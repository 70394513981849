/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React from 'react'

type Props = {
  currentPage: number
  setCurrentPage(arg0: number): void
  totalPage: any
}

const PaginationWidget: React.FC<Props> = (props) => {
  return (
    <div className='d-flex flex-stack flex-wrap pt-10'>
      <div className='fs-6 fw-bold text-gray-700'></div>
      <ul className='pagination'>
        <li className={clsx('page-item previous', props?.currentPage == 1 && 'disabled')}>
          <button
            type='button'
            className='page-link'
            onClick={() => props?.setCurrentPage(props?.currentPage - 1)}
          >
            <i className='previous'></i>
          </button>
        </li>

        {props?.totalPage?.map((num: any) => {
          return (
            <li className={clsx('page-item ', num == props?.currentPage ? 'active' : '')} key={num}>
              <button
                type='button'
                className='page-link'
                onClick={() => props?.setCurrentPage(num)}
              >
                {num}
              </button>
            </li>
          )
        })}

        <li
          className={clsx(
            'page-item next',
            props?.currentPage === +props?.totalPage?.length && 'disabled'
          )}
        >
          <button
            type='button'
            className='page-link'
            onClick={() => props?.setCurrentPage(props?.currentPage + 1)}
          >
            <i className='next'></i>
          </button>
        </li>
      </ul>
    </div>
  )
}

export {PaginationWidget}

import { useEffect } from 'react'
import { auth_details } from '../../../store/Auth/AuthSlice'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import { loaderChange } from '../../../store/Loader/LoaderSlice'
import { FeedsWidget5 } from '../../../_metronic/partials/widgets'

const AuthPage = () => {
  const dispatch = useDispatch()
  const parsed = queryString.parse(window.location.search)
  const auth_detail = useSelector((state: any) => state.authSlice.auth_details)
  const user_type = localStorage.getItem('user_type')

  useEffect(() => {
    if (localStorage.getItem('timezone')) {
      dispatch(loaderChange(true))
      dispatch(
        auth_details({
          event_id: Number(parsed.event_id),
          unique_key: parsed.unique_string,
        })
      )
        .then((res: any) => {
          dispatch(loaderChange(false))
        })
        .catch((err: any) => {
          dispatch(loaderChange(false))
        })
    }
  }, [])

  useEffect(() => {
    if (auth_detail?.event?.id) {
      // auth_detail?.status == 1 &&
      window.location.href = `/event/${auth_detail?.event?.id}/reception`;
    }
  }, [auth_detail])

  return (
    <div className='d-flex flex-column flex-root'>
      {user_type &&
        user_type.toLowerCase() != 'owner' &&
        auth_detail &&
        auth_detail?.event_name && (
          <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
            {auth_detail?.status == 0 && (
              <FeedsWidget5
                className={'w-700px'}
                name={auth_detail?.event_name}
                hosted_by={auth_detail?.event?.hosted_by}
                image={auth_detail?.event?.banner}
                description={auth_detail?.event?.description}
                startdatetime={auth_detail?.event?.startdatetime}
                enddatetime={auth_detail?.event?.enddatetime}
                msg={'Event starting in'}
                isEnded={false}
                event_id={auth_detail?.event?.id}
              />
            )}
            {auth_detail?.status == 2 && (
              <FeedsWidget5
                className={'w-700px'}
                name={auth_detail?.event_name}
                hosted_by={auth_detail?.event?.hosted_by}
                image={auth_detail?.event?.banner}
                description={auth_detail?.event?.description}
                startdatetime={auth_detail?.event?.startdatetime}
                enddatetime={auth_detail?.event?.enddatetime}
                msg={'Event ended'}
                isEnded={true}
                event_id={auth_detail?.event?.id}
              />
            )}
          </div>
        )}
    </div>
  )
}
export default AuthPage

import { Routes, Route } from 'react-router-dom'
import { ComplaintForm } from '../accounts/components/settings/cards/ComplaintForm'

const EventPage = () => {
    return (
        <Routes>
            <Route>
                <Route
                    path='/complaint-form'
                    element={
                        <ComplaintForm />
                    }
                />
                {/* <Route index element={<Navigate to='/:id/reception' />} /> */}
            </Route>
        </Routes>
    )
}
export default EventPage

import { FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import EventPage from '../modules/event/EventPage'
import CompanyEventPage from '../modules/company-event/CompanyEventPage'
import ChatProvider from '../modules/Socket/ChatProvider'
import AuthPage from '../modules/event/AuthPage'
import PollProvider from '../modules/Socket/PollProvider'
import AccountPage from '../modules/complaints/AccountPage'
import SocketProvider from '../modules/Socket/SocketProvider'
import { LiveStage } from '../modules/event/livestage/LiveStage'
import { CompanyMasterLayout } from '../../_metronic/layout/CompanyMasterLayout'

const PrivateRoutes = () => {
  return (
    <SocketProvider>
      <ChatProvider>
        <PollProvider>
          <Routes>
            <Route path='auth/*' element={<AuthPage />} />
            <Route element={<MasterLayout />}>
              {/* Page Not Found */}
              <Route path='*' element={<Navigate to='/error/404' />} />
              <Route
                path='event/*'
                element={
                  <SuspensedView>
                    <EventPage />
                  </SuspensedView>
                }
              />

              <Route
                path='account/*'
                element={
                  <SuspensedView>
                    <AccountPage />
                  </SuspensedView>
                }
              />
            </Route>
            <Route
              path='event/:id/live-stage/:livestage_id'
              element={
                <>
                  <LiveStage />
                </>
              }
            />
            <Route element={<CompanyMasterLayout />}>
              <Route
                path='company-event/*'
                element={
                  <SuspensedView>
                    <CompanyEventPage />
                  </SuspensedView>
                }
              />
            </Route>
          </Routes>
        </PollProvider>
      </ChatProvider>
    </SocketProvider>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }

import { FC, useState } from 'react'
import clsx from 'clsx'
import { KTSVG } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, Search } from '../../../partials'
import { useLayout } from '../../core'
import { ChatState } from '../../../../app/modules/Socket/ChatProvider'
import { SocketState } from '../../../../app/modules/Socket/SocketProvider'
import { useIntl } from 'react-intl'

const itemClass = 'ms-1 ms-lg-2 ms-md-2',
  btnClass =
    'btn btn-sm btn-active-light-primary w-50px h-50px w-md-50px h-md-50px text-center overflow-wrap menu-link menu-center',
  userAvatarClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  const { config } = useLayout()
  const intl = useIntl()
  const {
    setIsGroup,
    getMessages,
    pollNotificationCount,
    privateChatNotificationCount,
    groupChatNotificationCount,
    chatList: { notificationList },
  } = ChatState()
  const { socket } = SocketState()
  const [limit, setLimit] = useState(30)
  const groupId = localStorage.getItem('groupId')
  const userId = Number(localStorage.getItem('user_id'))
  const handleReadAllNotification = (type: string) => {
    socket.emit('read_notifications', { user_id: userId, notification_type: type })
  }
  const event_type = localStorage.getItem('type') || '0'
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Poll */}
      {/* {event_type == '1' && (
        <div className={clsx('d-flex align-items-center', itemClass)}>
          <div
            className='btn btn-sm btn-active-light-primary w-50px h-50px w-md-50px h-md-50px text-center'
            id='kt_poll_toggle'
            onClick={() => pollNotificationCount > 0 && handleReadAllNotification('poll')}
          >
            <i className={clsx('fa-solid fa-list-check fs-2 me-2 position-relative')}>
              {pollNotificationCount > 0 && (
                <label
                  className='btn btn-icon btn-circle btn-primary h-20px notification-dot w-20px'
                  style={{ position: 'absolute', left: '50%', top: '-20px' }}
                >
                  {pollNotificationCount > 99 ? '99+' : pollNotificationCount}
                </label>
              )}
            </i>{' '}
            Poll
          </div>
        </div>
      )} */}

      {/*GROUP CHAT */}
      <div
        className={clsx('d-flex align-items-center position-relative', itemClass)}
        onClick={() => {
          localStorage.setItem('isGroupOpen', 'true')
          setIsGroup(true)
          getMessages(groupId)
          groupChatNotificationCount > 0 && socket.emit('group_read_by', { chat_id: groupId })
        }}
      >
        <div className={btnClass} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/svg/event/chat-left.svg' className='svg-icon-2 me-2 position-relative' />
          {intl.formatMessage({ id:"MENU.CHAT"})}
        </div>
        {groupChatNotificationCount > 0 &&
          <span className='bullet bullet-dot bg-primary h-6px w-6px position-absolute translate-middle start-50 animation-blink' style={{ top: "5px" }} />}
      </div>

      {/* CHAT */}
      <div
        className={clsx('d-flex align-items-center', itemClass)}
        onClick={() => {
          localStorage.setItem('isGroupOpen', 'false')
          setIsGroup(false)
          privateChatNotificationCount > 0 && handleReadAllNotification('general')
        }}
      >
        <Search />
      </div>
      <div className={clsx('d-flex align-items-center', itemClass)}>
        {/* <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className='btn btn-sm btn-active-light-primary w-100px h-50px w-md-100px h-md-50px text-center '
          onClick={handleReadAllNotification}
        >
          <i className={clsx('fas fa-bell fs-2 me-2 position-relative')}>
            {notificationList?.unread_count > 0 && (
              <label
                className='btn btn-icon btn-circle btn-primary h-20px notification-dot w-20px'
                style={{ position: 'absolute', left: '45%' }}
              >
                1
              </label>
            )}
          </i>{' '}
          Notifications
          {notificationList?.unread_count > 0 ? (
            <span className='notification-dot'>{notificationList?.unread_count}</span>
          ) : (
            <span className='notification-dot'></span>
          )}
        </div> */}
        <HeaderNotificationsMenu setLimit={setLimit} limit={limit} />
      </div>
      {/* begin::User */}
      <div className={clsx('d-flex align-items-center', itemClass)} id='kt_header_user_menu_toggle'>
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={localStorage.getItem('profile_pic') || ''} alt='no-img' />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px d-none'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export { Topbar }

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useParams } from 'react-router-dom'
import { Card7 } from '../../../../_metronic/partials/content/cards/Card7'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { nominees, list_of_nominees } from '../../../../store/Award/AwardSlice';
import { loaderChange } from '../../../../store/Loader/LoaderSlice';
import { AwardHeader } from '../AwardHeader';
import { useIntl } from 'react-intl';

export function Nominees() {
  const { award_id, event_id } = useParams();
  const nomineeList = useSelector(nominees)
  const dispatch = useDispatch()
  const [disable, setDisable] = useState(false)
  const intl = useIntl()
  
  const getNominees = () => {
    dispatch(loaderChange(true))
    dispatch(list_of_nominees({ award_id: award_id })).then((res: any) => {
      dispatch(loaderChange(false))
    })
      .catch((err: any) => {
        dispatch(loaderChange(false))
      })
  }

  useEffect(() => {
    getNominees()
  }, [])

  useEffect(() => {
    nomineeList?.length > 0 && setDisable(nomineeList.filter((n: any) => n.voting_percentage != null && n.voting_percentage != 0).length == 0 ? false : true)
  }, [nomineeList])

  return (
    <>
      <AwardHeader />
      <div className={`card row`}>
        <div className='card-header d-flex flex-wrap flex-stack'>
          <h3 className='card-title fw-bolder my-2'>
            {intl.formatMessage({ id: "TEXT.NOMINEE" })}
          </h3>
        </div>

        <div className='row card-body'>
          {nomineeList?.length > 0 ?
            (
              nomineeList?.map((nominee: any, i: number) =>
              (<div className='col-md-6 col-xl-4 mb-5' key={i}>
                <Card7
                  nominee_id={nominee?.id}
                  icon={nominee?.image}
                  nominee_name={nominee?.name}
                  movie_name={nominee?.movie_name}
                  video={nominee?.video}
                  progress={nominee?.voting_percentage || 0}
                  disable={disable}
                />
              </div>)
              ))
            : <div className='pl-3'>{intl.formatMessage({ id: "MSG.NOMINEE_NOT_FOUND" })}</div>}
        </div>
      </div >
    </>
  )
}
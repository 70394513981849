/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Card3} from '../../../../_metronic/partials/content/cards/Card3'

export function Connections(props: any) {
  
  return (
    <>
      <div className='row g-6 g-xl-9'>
        {props?.guests?.length > 0 ? (
          <>
            {props &&
              props?.guests?.map((g: any) => {
                return (
                  <div className='col-md-6 col-xxl-4'>
                    <Card3
                      avatar={g?.image ? g?.image : '/media/avatars/blank.png'}
                      name={g?.first_name + ' ' + g?.last_name}
                      email={g?.email}
                      type={g?.GuestMaster?.title}
                      headline={g?.headline}
                    />
                  </div>
                )
              })}
          </>
        ) : (
          'No guests'
        )}
      </div>
    </>
  )
}

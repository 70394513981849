import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {API_BOOTH_DETAILS, API_BOOTH_LIST} from '../../constants/Api'

const initialState = {
  message: '',
  error: null,
  boothList: [],
  boothDetails: null,
}

export const boothListAction: any = createAsyncThunk('boothList', async (id: any) => {
  return await axios
    .get(`${API_BOOTH_LIST}${id}`)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      console.log(err)
      return err
    })
})

export const boothDetailsAction: any = createAsyncThunk('boothDetails', async (id: any) => {
  return await axios
    .get(`${API_BOOTH_DETAILS}${id}`)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      console.log(err)
      return err
    })
})

// Reducer
const BoothSlie = createSlice({
  name: 'booth',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //boothList
    builder.addCase(boothListAction.fulfilled, (state, action: any) => {
      let data = action.payload?.data
      state.boothList = data
      state.message = action.payload?.message
      state.error = null
    })

    builder.addCase(boothListAction.rejected, (state, action: any) => {
      state.boothList = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })

    builder.addCase(boothDetailsAction.fulfilled, (state, action: any) => {
      let data = action.payload?.data
      state.boothDetails = data
      state.message = action.payload?.message
      state.error = null
    })

    builder.addCase(boothDetailsAction.rejected, (state, action: any) => {
      state.boothDetails = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })
  },
})

export default BoothSlie.reducer

export const boothList = (state: any) => state.booth.boothList
export const boothDetails = (state: any) => state.booth.boothDetails

import { FC, useEffect } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { App } from '../App'
import { ThemeModeType, useThemeMode } from '../../_metronic/partials'
import ReduxToastr from 'react-redux-toastr'
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { updateMode, updateMenuMode } = useThemeMode()

  // change mode
  const changeMode = (_mode: ThemeModeType) => {
    updateMenuMode(_mode)
    updateMode(_mode)
  }

  useEffect(() => {
    changeMode("light")
  }, [])

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <ReduxToastr />
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='/*' element={<PrivateRoutes />} />
          <Route element={<Navigate to='/event/1/reception' />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useLocation} from 'react-router-dom'
import {Connections} from './components/Connections'
import {eventDetails} from '../../../store/Event/EventSlice'
import {useSelector} from 'react-redux'

const GuestPage: React.FC = () => {
  const event_details = useSelector(eventDetails)

  return (
    <div className={`card row pt-5 pb-5`}>
      {/* begin::Body */}
      <div className='card-body pb-0'>
        <Connections guests={event_details?.guests} />
      </div>
      {/* end::Body */}
    </div>
  )
}

export {GuestPage}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { JitsiMeeting } from '@jitsi/react-sdk'
import { useIntl } from 'react-intl'

const VideoCall: React.FC = () => {
    const intl = useIntl()
    const navigate = useNavigate();
    const { meeting_id, id } = useParams()
    const dispatch = useDispatch()
    const [config, setConfig] = useState({})
    const [externalApi, setExternalApi]: any = useState()

    useEffect(() => {
        setConfig({
            // doNotFlipLocalVideo: true,
            toolbarButtons: ['microphone', 'camera', 'desktop', 'chat', 'participants-pane', 'fullscreen'],
        })
    }, [])

    const endCall = () => {
        // if (userType == 'Sender') {
        //     externalApi?.executeCommand('endConference')
        //     externalApi?.executeCommand('hangup')
        // }
        // if (userType == 'Receiver')
        //     externalApi?.executeCommand('displayName', localStorage.getItem('user_name'))
        navigate(`/event/${id}/reception`);
    }

    return (
        <>
            <div className={`card row mb-10`}>
                <div className='card-header'>
                    <h3></h3>
                    <div className='card-toolbar'>
                        <button
                            className='btn btn-sm btn-danger theme-same-button'
                            type='button'
                            onClick={() => endCall()}
                        >
                            {intl.formatMessage({ id: "TEXT.ENDCALL" })}
                        </button>
                    </div>
                </div>

                <div className='card-body pb-10'>
                    <div className='h-550px w-100'                     >
                        <JitsiMeeting
                            domain={process.env.REACT_APP_VIDEO_DOMAIN}
                            roomName={meeting_id || ''}
                            configOverwrite={config}
                            interfaceConfigOverwrite={{
                                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                                ENABLE_END_CONFERENCE: true,
                            }}
                            userInfo={{
                                displayName: localStorage.getItem('user_name') || '',
                                email: '',
                            }}
                            spinner={() => (
                                <div className='h-400px text-center'>
                                    <h3 className=' mt-50'>Loading.. Please Wait</h3>
                                </div>
                            )}
                            getIFrameRef={(iframeRef: { style: { height: string } }) => {
                                iframeRef.style.height = '550px'
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export { VideoCall }

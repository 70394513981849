import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { loaderChange } from '../../../../store/Loader/LoaderSlice'
import { CompanyDetails } from '../../../../_metronic/partials/widgets/company-event/CompanyDetails'
import { companyDetails, companyDetailsAction, companyEventList, companyEventListAction } from '../../../../store/Company/CompanySlice'

const EventsPage: React.FC = () => {
  const company_id = useParams().company_id;
  const dispatch = useDispatch();
  const companyDetail = useSelector(companyDetails)
  const eventList = useSelector(companyEventList)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  let [totalPage, setTotalPage] = useState<any>([])

  useEffect(() => {
    localStorage.clear()
    if (!localStorage.getItem('token') && localStorage.getItem('token') == null) {
      localStorage.setItem('token',
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjAwLCJyb2xlIjoiMSIsImlhdCI6MTcwMzkzODA4OX0.qt4maybv7lvvLovoEsMPBABtcwdJSiobD9vy0SNC5i4'
      )
    }
    localStorage.setItem("bananryCompanyId", company_id || "")
    localStorage.setItem("event_type", "company_event")
    dispatch(loaderChange(true))
    dispatch(companyDetailsAction({ company_id: company_id })).then(() => {
      dispatch(loaderChange(false))
    })
  }, [])

  useEffect(() => {
    localStorage.setItem("company_logo", companyDetail?.company_logo || "")
  }, [companyDetail])

  useEffect(() => {
    let total = []
    for (let i = 0; i <= eventList?.total / limit; i++) {
      total.push(i + 1)
    }
    setTotalPage(total)
  }, [eventList?.total])

  useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem('token') != null) {
      dispatch(loaderChange(true))
      dispatch(companyEventListAction({ page: page, limit: limit, company_id: company_id }))
      dispatch(loaderChange(false))
    }
  }, [page])

  return (
    <>
      {/* begin::Header */}
      <CompanyDetails eventList={eventList} company_details={companyDetail} page={page} limit={limit} totalPage={totalPage} setPage={setPage} />
    </>
  )
}

export { EventsPage }

import * as ACTION_TYPES from './Types'
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {API_EVENT} from '../../constants/Api'

const initialState = {
  message: '',
  error: null,
  event: {},
}

export const event_detail:any = createAsyncThunk('event_detail', async (data: any) => {
  return await axios(API_EVENT + data.event_id)
})

// Reducer
const SponserSlice = createSlice({
  name: 'event',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ACTION_TYPES.FETCH_SPONSER_DETAILS_SUCCESS, (state, action: any) => {
      state.event = action.payload.data
      state.message = action.payload.message
      state.error = null
    })
    builder.addCase(ACTION_TYPES.FETCH_SPONSER_DETAILS_ERROR, (state, action: any) => {
      state.event = action.payload.data
      state.message = action.payload.message
      state.error = null
    })
  },
})

export default SponserSlice.reducer

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card5 } from '../../../../_metronic/partials/content/cards/Card5'
import { awards, list_of_awards } from '../../../../store/Award/AwardSlice';
import { loaderChange } from '../../../../store/Loader/LoaderSlice';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl'
import clsx from 'clsx';


export function AwardList(props: any) {
  const params = useParams();
  const id = params?.event_id;
  const company_id = params?.company_id;
  const dispatch = useDispatch()
  const awardList = useSelector(awards)
  const intl = useIntl()


  useEffect(() => {
    dispatch(loaderChange(true))
    dispatch(list_of_awards({ event_id: id })).then((res: any) => {
      dispatch(loaderChange(false))
    })
      .catch((err: any) => {
        dispatch(loaderChange(false))
      })
  }, [])

  return (
    <>
      <div className={`card pt-5 mb-10`}>
        <div className='card-header d-flex flex-wrap flex-stack'>
          <h3 className='card-title fw-bolder my-2'>
            {intl.formatMessage({ id: 'AWARDS.AWARDLIST' })}
          </h3>
        </div>

        <div className={clsx('card-body row', awardList?.length > 0 && "mx-5 me-5")}>
          {awardList?.length > 0 ?
            (
              awardList?.map((award: any, i: number) =>
                <div className='col-sm-6 col-xl-4 mb-5' key={i}>
                  <Card5
                    link={company_id ? `/company-event/${company_id}/${id}/${award?.id}/nominees` : `/event/${id}/awards/${award?.id}/nominees`}
                    image={award?.image}
                    title={award?.title}
                    description={award?.description}
                    category={award?.category}
                    progress={0.5}
                  />
                </div>)
            )
            : <div className='pl-3'>{intl.formatMessage({ id: 'AWARDS.NOT.FOUND' })}</div>}
        </div>
      </div>
    </>
  )
}

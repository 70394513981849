import React, { useEffect } from 'react'
import { eventDetails, event_detail } from '../../../store/Event/EventSlice'
import { useDispatch, useSelector } from 'react-redux'
import { loaderChange } from '../../../store/Loader/LoaderSlice'

const ProfileHeader: React.FC = (props: any) => {
  const id = localStorage.getItem("event_id");
  const dispatch = useDispatch()
  const event_details = useSelector(eventDetails)

  useEffect(() => {
    dispatch(loaderChange(true))
    dispatch(event_detail({ event_id: id })).then((res: any) => {
      dispatch(loaderChange(false))
    })
      .catch((err: any) => {
        dispatch(loaderChange(false))
      })
  }, [])

  return (
    <div className='card row mb-10'>
      <div className='card-body col-12 pt-9 pb-0'>
        <div className='image-input-wrapper w-auto min-h-400px bg-light-secondary' style={{
          background: `url(${event_details?.event?.banner
            && event_details?.event?.banner})`
        }}></div>
        <div className='p-5'></div>
      </div>
    </div>
  )
}

export { ProfileHeader }

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

const Anouncement: FC = () => {
  return (
    <div className='modal fade' id='kt_modal_announcement' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-5'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Create Announcement</h1>

              <div className='text-muted fw-bold fs-5'>
                Share an important message with your audience
              </div>
            </div>
            <label className='fw-bold fs-5'>Announcement message</label>
            <textarea
              className='form-control form-control-solid mb-8'
              rows={3}
              placeholder='Your announcement message'
            ></textarea>
          </div>

          <div className='card-footer mx-5 mx-xl-20 pt-0 pb-15'>
            <div className='text-end'>
              <button
                className='btn btn-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_announcement'
              >
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Anouncement}

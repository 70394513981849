import React, { FC, useEffect, useRef, useState } from 'react'
import { SearchComponent } from '../../../assets/ts/components'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { DrawerMessenger } from '../drawer-messenger/DrawerMessenger'
import { ChatState } from '../../../../app/modules/Socket/ChatProvider'
import clsx from 'clsx'
import { useIntl } from 'react-intl'

const Search: FC = () => {
  const [menuState, setMenuState] = useState<'main' | 'advanced' | 'preferences'>('main')
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const suggestionsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)
  const userId = Number(localStorage.getItem('user_id'))
  const { chatList, createPrivateChat, readBy, privateChatNotificationCount, handleChange } = ChatState()
  const intl = useIntl()

  useEffect(() => {
    // Initialize search handler
    const searchObject = SearchComponent.createInsance('#kt_header_search')

    // // Search handler
    // searchObject!.on('kt.search.process', processs)

    // // Clear handler
    // searchObject!.on('kt.search.clear', clear)
  }, [])
  const handleCloseChatList = () => {
    const ele = document.getElementById('chat_list_box')
    if (!ele) return

    if (ele.classList.contains('for_manual_close')) {
      ele.style.display = 'none'
      ele.classList.remove('for_manual_close')
    } else {
      ele.style.display = 'flex'
    }
  }
  return (
    <>
      <div
        id='kt_header_search'
        className='d-flex align-items-stretch'
        data-kt-search-keypress='true'
        data-kt-search-min-length='2'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-end'
        ref={element}
      >
        <div
          className='d-flex align-items-center'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
        >
          <div className='btn btn-sm btn-active-light-primary w-50px h-50px w-md-50px h-md-50px text-center position-relative'>
            <KTSVG path='/media/svg/event/people-fill.svg' className='svg-icon-2 me-2 position-relative'></KTSVG>
            {privateChatNotificationCount > 0 && (
              <label
                className='btn btn-icon btn-circle btn-primary h-20px notification-dot w-20px fs-8'
                style={{ position: 'absolute', left: '65%', top: '-5px', fontStyle: 'normal' }}
              >
                {privateChatNotificationCount > 99 ? '99+' : privateChatNotificationCount}
              </label>
            )}

            {intl.formatMessage({ id: "MENU.USERS" })}
          </div>
        </div>

        <div
          data-kt-search-element='content'
          id='chat_list_box'
          className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px pb-0'
        >
          <div
            className={`${menuState === 'main' ? '' : 'd-none'}`}
            ref={wrapperElement}
            data-kt-search-element='wrapper'
          >
            <div data-kt-search-element='form' className='w-100 position-relative mb-3'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
              />

              <input
                type='text'
                className='form-control form-control-flush ps-10'
                name='search'
                placeholder={intl.formatMessage({ id: "TEXT.PLACEHODER_SEARCH" })}
                data-kt-search-element='input'
                onChange={(e) => handleChange(e.target.value)}
                autoComplete='off'
              />
            </div>
            <div ref={suggestionsElement} className='mb-4' data-kt-search-element='main'>
              <div className='d-flex flex-stack fw-bold mb-4'>
                <span className='text-muted fs-6 me-2'>{intl.formatMessage({ id: "MENU.USERS" })}:</span>
              </div>

              <div className='scroll-y mh-200px mh-lg-325px'>
                {chatList?.chatList?.length > 0 ? (
                  chatList.chatList
                    .filter((c: any) => c.id !== userId)
                    .map((chat: any, i: number) => (
                      <div
                        className='d-flex align-items-center mb-5'
                        data-bs-toggle='modal'
                        id='kt_drawer_chat_toggle'
                        key={i}
                      >
                        <div className='symbol symbol-40px me-4'>
                          {chat?.profile_pic ? (
                            <img src={chat.profile_pic} alt='no-img' />
                          ) : (
                            <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                              {chat.name.charAt(0).toUpperCase()}
                            </span>
                          )}
                        </div>
                        <div className='d-flex justify-content-between w-100 align-items-center'>
                          <p className='fs-6 text-gray-800 text-hover-primary fw-bold cursor-pointer mb-0' onClick={() => {
                            createPrivateChat(Number(chat.id))
                            chat.unread_messages !== 0 && readBy(chat.chat_id, Number(chat.id))
                          }}>
                            {chat.name}
                          </p>

                          {chat.unread_messages !== 0 && (
                            <span className='ms-2 badge badge-sm badge-circle bg-primary'>
                              {chat.unread_messages}
                            </span>
                          )}
                        </div>
                      </div>
                    ))
                ) : (
                  <div className='text-center pt-10'>
                    <div className='pt-10 pb-10'>
                      <KTSVG
                        path='/media/icons/duotune/files/fil024.svg'
                        className='svg-icon-4x opacity-50'
                      />
                    </div>

                    <div className='pb-10 fw-bold'>
                      <h3 className='text-gray-600 fs-5 mb-2'>{intl.formatMessage({ id: "TEXT.NO_RESULT_FOUND" })}</h3>
                      <div className='text-muted fs-7'>{intl.formatMessage({ id: "TEXT.NO_USERS_ONLINE" })}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { Search }

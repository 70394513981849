/* eslint-disable react/jsx-no-target-blank */
import { AsideMenuItem } from './AsideMenuItem'
import { useEffect, useState } from 'react'
import { loaderChange } from '../../../../store/Loader/LoaderSlice';
import { SessionList, session_list } from '../../../../store/Session/SessionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl'


export function AsideMenuMain() {
  const id = localStorage.getItem("event_id");
  const dispatch = useDispatch();
  const event_type = localStorage.getItem('type');
  const [isLive, setIsLive] = useState(false)
  const sessions = useSelector(SessionList)

  useEffect(() => {
    if (localStorage.getItem('timezone')) {
      dispatch(loaderChange(true))
      dispatch(session_list({ event_id: id })).then((res: any) => {
        dispatch(loaderChange(false))
      })
        .catch((err: any) => {
          dispatch(loaderChange(false))
        })
    }
  }, [])

  useEffect(() => {
    if (sessions?.length > 0) {
      sessions?.sessionList?.map((session: any) => {
        if (session?.isLive == 1) setIsLive(session?.isLive)
      })
    }
  }, [sessions])

  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to={`/event/${id}/reception`}
        title={intl.formatMessage({ id: 'SIDEBAR.RECEPTION' })}
        bsTitle={intl.formatMessage({ id: 'SIDEBAR.RECEPTION' })}
        fontIcon='/media/svg/event/house.svg'
        className='py-2'
      />

      <AsideMenuItem
        to={`/event/${id}/schedules`}
        title={intl.formatMessage({ id: 'SIDEBAR.SCHEDULES' })}
        bsTitle={intl.formatMessage({ id: 'SIDEBAR.SCHEDULES' })}
        fontIcon='/media/svg/event/calendar-week.svg'
        className='py-2'
      />

      <AsideMenuItem
        to={`/event/${id}/sessions`}
        title={intl.formatMessage({ id: 'SIDEBAR.SESSIONS' })}
        bsTitle={intl.formatMessage({ id: 'SIDEBAR.SESSIONS' })}
        fontIcon='/media/svg/event/people.svg'
        className='py-2'
        isLive={isLive}
      ></AsideMenuItem>

      <AsideMenuItem
        to={`/event/${id}/live-stages`}
        title={intl.formatMessage({ id: 'SIDEBAR.LIVESTAGE' })}
        fontIcon='/media/svg/event/camera-video.svg'
        bsTitle={intl.formatMessage({ id: 'SIDEBAR.LIVESTAGE' })}
        className='py-2 text-center'
      ></AsideMenuItem>

      {event_type == '8' && (
        <AsideMenuItem
          to={`/event/${id}/boothlist`}
          title={intl.formatMessage({ id: 'SIDEBAR.BOOTHS' })}
          fontIcon='/media/svg/event/shop-window.svg'
          bsTitle={intl.formatMessage({ id: 'SIDEBAR.BOOTHS' })}
          className='py-2 text-center'
        ></AsideMenuItem>
      )}

      {event_type == '1' || localStorage.getItem("event_type") == "company_event" && (
        <AsideMenuItem
          to={`/event/${id}/awards`}
          title={intl.formatMessage({ id: 'SIDEBAR.AWARDS' })}
          fontIcon='/media/svg/event/award-fill.svg'
          bsTitle={intl.formatMessage({ id: 'SIDEBAR.AWARDS' })}
          className='py-2 text-center'
        ></AsideMenuItem>
      )}

    </>
  )
}

import React, { useEffect, useState, useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { JitsiMeeting } from '@jitsi/react-sdk'
import { ChatState } from '../../../Socket/ChatProvider'
import { useIntl } from 'react-intl'

type Props = {
  jitsiModalOpen: boolean
  setJitsiModalOpen: any
  callId: string
  callType: string
  userType: string
  show: boolean
}

const JitsiCall: React.FC<Props> = ({
  jitsiModalOpen,
  setJitsiModalOpen,
  callId,
  callType,
  userType,
  show,
}) => {
  const intl = useIntl()
  const [config, setConfig] = useState({})
  const [hide, setHide] = useState(!show)
  const [externalApi, setExternalApi]: any = useState()
  const { userId, socket, selectedChatRoom } = ChatState()

  useEffect(() => {
    if (callType == 'Audio') {
      setConfig({
        // startWithAudioMuted: true,
        disableModeratorIndicator: true,
        startScreenSharing: true,
        startAudioOnly: true,
        toolbarButtons: ['microphone', 'camera', 'desktop'],
        //'participants-pane'
      })
    } else {
      setConfig({
        // doNotFlipLocalVideo: true,
        toolbarButtons: ['microphone', 'camera', 'desktop', 'chat', 'participants-pane'],
      })
    }
  }, [callType])

  const endCall = () => {
    setHide(true)
    if (userType == 'Sender') {
      externalApi?.executeCommand('endConference')
      externalApi?.executeCommand('hangup')
    }
    if (userType == 'Receiver')
      externalApi?.executeCommand('displayName', localStorage.getItem('user_name'))

    setJitsiModalOpen(false)
    window.location.reload()
  }

  return (
    <Modal
      id='kt_modal_jitsi_call'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={jitsiModalOpen}
      dialogClassName='modal-xl'
      aria-hidden='true'
      onHide={() => setJitsiModalOpen(false)}
      fullscreen={true}
    >
      <Modal.Header>
        <Modal.Title>{callType}</Modal.Title>
        {/* {!hide && ( */}
        <button
          className='btn btn-danger theme-same-button'
          type='button'
          onClick={() => endCall()}
        >
          {intl.formatMessage({ id: "TEXT.ENDCALL" })}
        </button>
        {/* )} */}
      </Modal.Header>
      <Modal.Body>
        <JitsiMeeting
          domain={process.env.REACT_APP_VIDEO_DOMAIN}
          roomName={callId}
          configOverwrite={config}
          interfaceConfigOverwrite={{
            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
            ENABLE_END_CONFERENCE: true,
          }}
          userInfo={{
            displayName: localStorage.getItem('user_name') || '',
            email: '',
          }}
          spinner={() => (
            <div className='h-400px text-center'>
              <h3 className=' mt-50'>Loading.. Please Wait</h3>
            </div>
          )}
          getIFrameRef={(iframeRef) => {
            iframeRef.style.height = '650px'
          }}
        />
      </Modal.Body>
    </Modal>
  )
}
export { JitsiCall }

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { Link } from 'react-router-dom'

type Props = {
  icon: string
  title: string
  description: string
  class_name: any
  link: string
}

const Card6: FC<Props> = ({ link, icon, title, description, class_name }) => {
  return (
    <div className='card-body d-flex justify-content-center text-center flex-column p-5'>
      <Link to={`/event/booth/${link}`} className='text-gray-800 d-flex flex-column'>
        <div className={class_name}>
          <img
            src={toAbsoluteUrl(icon)}
            alt=''
          />
        </div>
        <div className='fs-5 fw-bolder mb-2'>{title}</div>
        <div className='fs-5 text-gray-400 mb-2'>{description}</div>
      </Link>
    </div>
  )
}

export { Card6 }

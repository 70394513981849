import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'

type Props = {
  image: string
  title: string
  email?: string
  hosted_by?: string
  desc: any
  website_link?: string
  twitter_link?: string
  facebook_link?: string
  linkedin_link?: string
  instagram_link?: string
}
let ProfileHeader2: React.FC<Props> = (props: any) => {
  const intl = useIntl()
  return (
    <div className='card-body pt-9 pb-0'>
      <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
        <div className='me-7 mb-4'>
          <div className='image-input-wrapper min-h-175px w-175px bg-light-secondary' style={{
            background: `url(${props.image
              ? props.image
              : toAbsoluteUrl('../../media/svg/files/blank-image.svg')})`
          }}></div>
        </div>

        <div className='flex-grow-1'>
          <div className='d-flex justify-content-between align-items-center flex-wrap mb-2'>
            <div className='d-flex flex-column'>
              <div className='d-flex align-items-center mb-3'>
                <label className='text-gray-800 fs-4 fw-bolder me-1'>{props?.title}</label>
              </div>
              {props?.hosted_by && (
                <div className='fs-6 text-gray-500'>
                  {intl.formatMessage({ id: "TEXT.HOSTED_BY" })} <strong className='fw-bold '>{props?.hosted_by}</strong>
                </div>
              )}
              {props?.email && (
                <div className='d-flex mb-2'>
                  <KTSVG path='/media/svg/event/envelope.svg' className='svg-icon-4 me-3' />
                  <a
                    href={'mailto:' + props?.email}
                    className='text-muted fw-bold text-hover-primary'
                  >
                    {props?.email}
                  </a>
                </div>
              )}

              <div className='d-flex flex-wrap fw-bold fs-6 mb-3 pe-2'>
                <div className='d-flex align-items-center text-gray-400 me-5'>
                  {props?.website_link && (
                    <a
                      href={props?.website_link || '#'}
                      className='btn btn-icon btn-sm btn-color-gray-500 btn-active-color-primary w-25px p-0'
                    >                      
                      <KTSVG path='/media/svg/event/globe.svg' className='svg-icon-4' />
                    </a>
                  )}

                  {props?.twitter_link && (
                    <a
                      className='btn btn-icon btn-sm btn-color-gray-500 btn-active-color-primary w-25px p-0'
                      href={props?.twitter_link || '#'}
                    >
                      <KTSVG path='/media/svg/event/twitter-x.svg' className='svg-icon-4' />
                    </a>
                  )}

                  {props?.facebook_link && (
                    <a
                      className='btn btn-icon btn-sm btn-color-gray-500 btn-active-color-primary w-25px p-0'
                      href={props?.facebook_link || '#'}
                    >
                      <KTSVG path='/media/svg/event/facebook.svg' className='svg-icon-4' />
                    </a>
                  )}

                  {props?.linkedin_link && (
                    <a
                      className='btn btn-icon btn-sm btn-color-gray-500 btn-active-color-primary w-25px p-0'
                      href={props?.linkedin_link || '#'}
                    >
                      <KTSVG path='/media/svg/event/linkedin.svg' className='svg-icon-4' />
                    </a>
                  )}

                  {props?.instagram_link && (
                    <a
                      className='btn btn-icon btn-sm btn-color-gray-500 btn-active-color-primary w-25px p-0'
                      href={props?.instagram_link || '#'}
                    >
                      <KTSVG path='/media/svg/event/instagram.svg' className='svg-icon-4' />
                    </a>
                  )}
                </div>
              </div>
              <div className='text-muted'>{props?.desc}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ProfileHeader2 }

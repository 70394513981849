/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { JitsiCall } from '../apps/modals/jisti-call/JistiCall'
import { loaderChange } from '../../../store/Loader/LoaderSlice'
import { SessionDetails, session_detail } from '../../../store/Session/SessionSlice'
import { convertToLocalTime } from '../../../utils'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../_metronic/helpers/components/KTSVG'
const parse = require('html-react-parser')

const SessionDetail: React.FC = () => {
  const intl = useIntl()
  const { session_id } = useParams()
  const dispatch = useDispatch()
  const session = useSelector(SessionDetails)
  const [jitsiModalOpen, setJitsiModalOpen] = useState(false)
  const [userType, setUserType] = useState('Sender')

  useEffect(() => {
    dispatch(loaderChange(true))
    if (session_id) {
      dispatch(session_detail({ session_id: session_id })).then(() =>
        dispatch(loaderChange(false))
      )
    }
  }, [])

  return (
    <>
      <div className={`card row mb-10`}>
        {session_id && (
          <div className='card-body col-12'>
            <div
              className='d-flex justify-content-center flex-column flex-center h-550px'
              style={{ backgroundColor: '#212f44' }}
            >
              <div className='d-flex justify-content-center flex-column flex-center h-500px'>
                <div className='no-polls-logo'>
                  <KTSVG path='/media/svg/event/people.svg' className='svg-icon-3 message-logo' />
                </div>
                <p className='mt-3 text-white'>{intl.formatMessage({ id: "TEXT.JOIN_TEXT" })}</p>
                <button
                  className='btn btn-md btn-primary'
                  onClick={() => {
                    setJitsiModalOpen(true)
                  }}
                >
                  {intl.formatMessage({ id: "TEXT.JOIN" })}
                </button>
              </div>
            </div>
            <div className='mb-2 mt-10'>
              <h2 className='text-gray-800 fw-bolder'>{session?.title}</h2>
            </div>
            <div className='text-gray-500 fw-semibold mb-10'> {session?.startdatetime && session?.enddatettime && (
              <>
                {convertToLocalTime(session?.startdatetime)} <strong>{intl.formatMessage({ id: "TEXT.TO" })}</strong> {" "}
                {convertToLocalTime(session?.enddatettime)}
              </>
            )}</div>
            <div className='fw-mormal text-gray-500 mb-10'>{session?.description ? parse(session?.description) : ""}</div>

            {session?.guests?.length > 0 && (
              <>
                <hr />
                <div className='mt-10 mb-10'>
                  <span className='text-dark fw-bold fs-6'>{intl.formatMessage({ id: "SCHEDULE.GUEST.DETAILS" })}</span>
                  {session?.guests.map((guest: any, i: number) => (
                    <div className='d-flex align-items-center mb-5 mt-5' key={i}>
                      {/* begin::Avatar */}
                      <div className='symbol symbol-50px me-5'>
                        {guest?.image ? <img
                          src={
                            guest?.image
                          }
                          className=''
                          alt=''
                        /> : <img
                          src={
                            '/media/avatars/blank.png'
                          }
                          className=''
                          alt=''
                        />}

                      </div>
                      {/* end::Avatar */}
                      {/* begin::Text */}
                      <div className='flex-grow-1'>
                        <span className='text-dark fw-bold fs-6'>
                          {guest?.first_name}{' '}
                          {guest?.last_name}
                          <>
                            {" "} ({guest?.guest_type})
                          </>
                        </span>
                        <span className='text-muted d-block fw-semibold'>
                          {guest?.headline}
                        </span>
                      </div>
                      {/* end::Text */}
                    </div>
                  ))}
                </div>
              </>
            )}

          </div>
        )}
      </div>
      <JitsiCall
        jitsiModalOpen={jitsiModalOpen}
        setJitsiModalOpen={setJitsiModalOpen}
        callId={session?.callId}
        callType={session?.title}
        userType={userType}
        show={false}
      />
    </>
  )
}

export { SessionDetail }

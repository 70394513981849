/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { IconUserModel } from '../../../../app/modules/event/ProfileModels'
import clsx from 'clsx'
import { convertToLocalTime } from '../../../../utils'
import { useIntl } from 'react-intl'
const parse = require('html-react-parser')

type Props = {
  link?: string
  icon: string
  title: string
  description: string
  date?: string
  budget?: string
  progress?: number
  users?: Array<IconUserModel>
  totalView?: number
  isLive?: any
  startdatetime?: any
  enddatettime?: any
}

const Card2: FC<Props> = ({
  link,
  icon,
  title,
  description,
  isLive,
  startdatetime,
  enddatettime,
}) => {
  const intl = useIntl()
  return (
    <Link
      to={link || ''}
      className='card shadow border border-2 border-gray-300 border-hover-primary overlay-wrapper bgi-no-repeat bgi-position-top bgi-size-cover card-rounded ribbon ribbon-end ribbon-clip'
    >
      <div className='card-header ribbon ribbon-end'>
        <div className='card-title d-block'>
          <div className='mt-3 fs-5 fw-bolder mb-1 text-gray-800'>{title}</div>
          <div className='fs-7 mb-1 text-gray-800'> {startdatetime && enddatettime && (
            <>
              {convertToLocalTime(startdatetime)} <strong>{intl.formatMessage({ id: "TEXT.TO" })}</strong> {" "}
              {convertToLocalTime(enddatettime)}
            </>
          )}</div>
        </div>
        <div
          className={clsx(
            'ribbon-label fw-bolder',
            isLive == 1 ? 'bg-success' : isLive == 2 ? 'bg-danger' : 'bg-primary'
          )}
        >
          {isLive == 1 ? intl.formatMessage({ id: "TEXT.LIVE" }) : isLive == 2 ? intl.formatMessage({ id: "TEXT.ENDED" }) : intl.formatMessage({ id: "TEXT.UPCOMING" })}
        </div>
      </div>
      <div className='card-body p-9'>
        <div className='bg-light w-auto text-center overflow'>
          <img
            src={icon}
            alt='card2'
            className='bgi-no-repeat bgi-position-center bgi-size-contain h-200px w-100'
          />
        </div>
      </div>
    </Link >
  )
}

export { Card2 }

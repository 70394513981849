import { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import { ILayout, useLayout } from '../core'
import { useSelector, useDispatch } from 'react-redux'
import { companyDetails, companyDetailsAction } from '../../../store/Company/CompanySlice'
import { loaderChange } from '../../../store/Loader/LoaderSlice'
import { subscribe } from '../../../store/Auth/AuthSlice'
import { useIntl } from 'react-intl'
import { setLanguage, useLang } from '../../i18n/Metronici18n'

const Footer: FC = (props: any) => {
  const { classes, config } = useLayout()
  const companyDetail = useSelector(companyDetails)
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [msgShow, setMsgShow] = useState(false);
  const [err, setErr] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl()
  const lang = useLang()

  const languages = [
    {
      lang: 'en',
      name: 'English',
    },
    {
      lang: 'hi',
      name: 'Hindi',
    },
    {
      lang: 'ar',
      name: 'Arabic',
    },
    {
      lang: 'fr',
      name: 'French',
    },
    {
      lang: 'de',
      name: 'German',
    },
    {
      lang: 'it',
      name: 'Italian',
    },
    {
      lang: 'ja',
      name: 'Japanese',
    },
    {
      lang: 'zh',
      name: 'Mandarin',
    },
    {
      lang: 'es',
      name: 'Spanish',
    },
    {
      lang: 'pt',
      name: 'portuguese',
    },
    {
      lang: 'ru',
      name: 'Russian',
    },
  ]
  const currentLanguage = languages.find((x: any) => x.lang === lang)

  const dispatch = useDispatch()

  useEffect(() => {
    if (localStorage.getItem('event_type') == "company_event") {
      dispatch(loaderChange(true))
      dispatch(companyDetailsAction({ company_id: localStorage.getItem("bananryCompanyId") }))
        .then((res: any) => {
          dispatch(loaderChange(false))
        })
        .catch((err: any) => {
          dispatch(loaderChange(false))
        })
    }
    return () => {
      setMsgShow(false)
    }
  }, [localStorage.getItem('event_type')])

  useEffect(() => {
    if (email != "") {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [email])

  const validateEmail = (email: any) => {
    // Regular expression for a basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const subscribeForNewslatter = (e: any) => {
    if (validateEmail(email)) {
      setIsLoading(true)
      e.preventDefault();
      dispatch(
        subscribe({
          company_id: Number(localStorage.getItem("bananryCompanyId")),
          email: email
        })
      )
        .then((res: any) => {
          console.log(res?.payload?.response?.data?.code)
          if (res?.payload?.response?.data?.code == 500) {
            setIsLoading(false)
            setMsgShow(false)
            setErr(res.payload.response.data.data)
          } else {
            setErr("")
            setMsgShow(true)
            setEmail("")
            setIsLoading(false)
          }
        })
        .catch((err: any) => {
          setIsLoading(false)
        })
    }
    else {
      setMsgShow(false)
      setErr("Please enter a valid email address")
      e.preventDefault();
    }

  }

  return (
    <div className={'footer py-4 d-flex flex-lg-column'} id='kt_footer'>
      {localStorage.getItem('event_type') && localStorage.getItem('event_type') == 'company_event' &&
        <><div className={clsx(
          classes.footerContainer,
          'row px-20 py-10'
        )}>
          <div className='col-md-3 col-sm-12'>
            <h3 className="fs-2 fw-bold mb-4 footer_text_color">{intl.formatMessage({ id: "TEXT.COMPANY_OVERVIEW" })}</h3>
            <div className='text-start'>
              <p className="fw-semibold fs-5 footer_text_color mb-2">
                {companyDetail?.company_name}
              </p>
              <p className="fw-semibold fs-5 footer_text_color mb-2">
                {companyDetail?.company_bio}
              </p>

            </div>
          </div>
          <div className='col-md-3 col-sm-12'>
            <h3 className="fs-2 fw-bold mb-4 footer_text_color">{intl.formatMessage({ id: "TEXT.REGISTERED_ADDRESS" })}</h3>
            <div className='text-start'>
              <p className="fw-semibold fs-5 footer_text_color mb-2">
                {companyDetail?.address}
              </p>
              <p className="fw-semibold fs-5 footer_text_color mb-2">
                {companyDetail?.city}
              </p>
            </div>
          </div>
          <div className='col-md-3 col-sm-12'>
            <h3 className="fs-2 fw-bold mb-4 footer_text_color">{intl.formatMessage({ id: "TEXT.OFFICE_CONTACT" })}</h3>
            <div className='text-start'>
              <p className="fw-semibold fs-5 footer_text_color mb-2">
                {companyDetail?.company_tl_number}
              </p>
            </div>
          </div>
          <div className='col-md-3 col-sm-12'>
            <h3 className="fs-2 fw-bold mb-4 text-center footer_text_color">{intl.formatMessage({ id: "TEXT.SUBSCRIBE_NEWSLATTER" })}</h3>
            <p className="footer_text_color fs-5 fw-semibold mb-4 text-center">
              {intl.formatMessage({ id: "TEXT.SUBSCRIBE_TEXT" })}
            </p>
            <div >
              <form onSubmit={subscribeForNewslatter} className='d-flex flex-column flex-md-row '>
                <input
                  type='email'
                  className='form-control ps-5'
                  name='email'
                  placeholder='xyz@gmail.com'
                  autoComplete='off'
                  value={email}
                  onChange={(e) => { setEmail(e.target.value); }}
                />
                <button type="submit" className={clsx('btn btn-sm btn-primary align-items-center w-150px mx-2', isLoading && "d-flex")} disabled={disabled}>
                  {isLoading ? (<>
                    {intl.formatMessage({ id: "BTN.SUBSCRIBE" })}&nbsp;
                    <svg
                      className='spinner'
                      width='13'
                      height='14'
                      viewBox='0 0 13 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M4.38798 12.616C3.36313 12.2306 2.46328 11.5721 1.78592 10.7118C1.10856 9.85153 0.679515 8.82231 0.545268 7.73564C0.411022 6.64897 0.576691 5.54628 1.02433 4.54704C1.47197 3.54779 2.1845 2.69009 3.08475 2.06684C3.98499 1.4436 5.03862 1.07858 6.13148 1.01133C7.22435 0.944078 8.31478 1.17716 9.28464 1.68533C10.2545 2.19349 11.0668 2.95736 11.6336 3.89419C12.2004 4.83101 12.5 5.90507 12.5 7'
                        stroke='white'
                      />
                    </svg>
                  </>) : intl.formatMessage({ id: "BTN.SUBSCRIBE" })}
                </button>
              </form>
            </div>
            {msgShow && <p className='text-success py-2'>{intl.formatMessage({ id: "MSG.SUBSCRIBEDD_SUCCESS" })}</p>}
            {err && <p className='text-danger py-2'>{err}</p>}
          </div>
        </div><hr className='text-white' /></>
      }
      {/*begin::Container*/}
      <div
        className={clsx(
          'd-flex align-items-center justify-content-center app-container pb-0 p-5  m-auto'
        )}
      >
        {/*begin::Copyright*/}

        <select
          name='locale'
          className='me-5 form-control'
          id='locale'
          style={{
            height: '27px',
            marginTop: '0px',
            lineHeight: '6px',
            width: '40%',
            padding: '5px 10px',
            fontSize: '14px',
            appearance: 'auto',
            borderRadius: "5px !important"
          }}
          value={currentLanguage?.lang}
          onChange={(e) => {
            setLanguage(e.target.value)
            localStorage.setItem('language', e.target.value)
          }}
        >
          <option value=''>Please Select Language</option>
          {languages.map((l) => (
            <option value={l.lang} key={l.lang}>
              {l.name}
            </option>
          ))}
        </select>
        <div className='footer_text_color'>
          <span className='me-1'>&copy; {new Date().getFullYear()}</span>
          <a
            href={process.env.REACT_APP_BANANRY_URL}
            target='_blank'
            className='footer_text_color fw-bold text-hover-primary'
          >
            Bananry
          </a>
          <sup>TM</sup> Inc. {intl.formatMessage({ id: "TEXT.ALL_RIGHTS_RESERVE" })}
        </div>
        {/*end::Copyright*/}
      </div>
      {/*end::Container*/}
    </div>
  )
}

export { Footer }

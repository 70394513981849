import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {API_LIVESTAGE, API_LIVESTAGE_DETAILS} from '../../constants/Api'

const initialState = {
  message: '',
  error: null,
  livestage: {},
  livestage_list: [],
}

export const livestage_detail: any = createAsyncThunk('livestage_detail', async (data: any) => {
  let res = await axios(API_LIVESTAGE_DETAILS + data.livestage_id)
  return res.data
})

export const livestage_by_event: any = createAsyncThunk('livestage_by_event', async (data: any) => {
  let res = await axios(API_LIVESTAGE + data.event_id)
  return res.data
})

// Reducer
const LiveStageSlice = createSlice({
  name: 'livestage',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //livestage_detail
    builder.addCase(livestage_detail.fulfilled, (state, action: any) => {
      state.livestage = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })
    builder.addCase(livestage_detail.rejected, (state, action: any) => {
      state.livestage = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })

    //livestage_by_event
    builder.addCase(livestage_by_event.fulfilled, (state, action: any) => {
      state.livestage_list = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })
    builder.addCase(livestage_by_event.rejected, (state, action: any) => {
      state.livestage_list = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })
  },
})

export default LiveStageSlice.reducer

export const livestageList = (state: any) => state.livestage.livestage_list
export const livestageDetails = (state: any) => state.livestage.livestage

import { FC } from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import queryString from 'query-string'

const Error500: FC = () => {
  const parsed = queryString.parse(window.location.search)
  return (
    <div className='d-flex flex-column flex-root'>
      {/*begin::Authentication - Error 500 */}
      <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
        {/* begin::Illustration */}
        <img
          src={toAbsoluteUrl('/media/auth/500-error.png')}
          alt=''
          className='mw-100 mb-10 h-lg-450px'
        />
        {/*end::Illustration*/}
        {/*begin::Wrapper*/}
        <div className='pt-lg-2 mb-2'>
          {/*begin::Message*/}
          {parsed.message ? (
            <div className='fw-bold fs-3 text-muted mb-5'>{parsed.message}</div>
          ) : (
            <div className='fw-bold fs-3 text-muted mb-15'>
              Something went wrong!
              <br />
              Please try again later.
            </div>
          )}
          {/*end::Message*/}
        </div>
        {/*end::Wrapper*/}

        {/*begin::Action*/}
        <div className='text-center'>
          <a href={process.env.REACT_APP_BANANRY_URL} className='btn btn-lg btn-primary fw-bolder'>
            Go to homepage
          </a>
          {/* <Link to='/' className='btn btn-lg btn-primary fw-bolder'>
                Go to homepage
              </Link> */}
        </div>
        {/*end::Action*/}
      </div>
      {/*end::Content*/}
    </div>
  )
}

export { Error500 }

import { Navigate, Routes, Route } from 'react-router-dom'
import { ProfileHeader } from './ProfileHeader'
import { ReceptionPage } from './ReceptionPage'
import { ScedulePage } from './ScedulePage'
import { SponserPage } from './SponserPage'
import { GuestPage } from './GuestPage'
import { SessionPage } from './SessionPage'
import { LiveStageLists } from './LiveStageLists'
import { SessionDetail } from './SessionDetail'
import { BoothListPage } from './BoothListPage'
import BoothDetails from '../booth/BoothDetails'
import AwardPage from '../awards/AwardPage'
import { LiveStageDetail } from './livestage/LiveStageDetail'
import { Nominees } from '../awards/components/Nominees'
import { VideoCall } from './videocall/VideoCall'

const EventPage = () => {
  return (
    <Routes>
      <Route>
        <Route
          path='/:id/reception'
          element={
            <>
              <ReceptionPage />
            </>
          }
        />
        <Route
          path='/:id/schedules'
          element={
            <ScedulePage />
          }
        />
        <Route
          path='/:id/sponsers'
          element={
            <>
              <ProfileHeader />
              <SponserPage />
            </>
          }
        />
        <Route
          path='/:id/guests'
          element={
            <>
              <ProfileHeader />
              <GuestPage />
            </>
          }
        />

        <Route
          path='/:id/live-stages'
          element={
            <>
              <LiveStageLists />
            </>
          }
        />

        <Route
          path='/:id/live-stages/:livestage_id'
          element={
            <>
              <LiveStageDetail />
            </>
          }
        />

        <Route
          path='/:id/sessions'
          element={
            <>
              <SessionPage />
            </>
          }
        />
        <Route
          path='/:id/session/:session_id'
          element={
            <>
              <SessionDetail />
            </>
          }
        />
        <Route
          path='/:id/boothlist'
          element={
            <>
              <BoothListPage />
            </>
          }
        />
        <Route
          path='/booth/:id'
          element={
            <>
              <BoothDetails />
            </>
          }
        />

        <Route
          path='/:id/awards'
          element={
            <>
              <AwardPage />
            </>
          }
        />
        <Route
          path='/:id/awards/:award_id/nominees'
          element={
            <>
              <Nominees />
            </>
          }
        />

        <Route
          path='/:id/meeting/:meeting_id'
          element={
            <>
              <VideoCall />
            </>
          }
        />

        <Route index element={<Navigate to='/:id/reception' />} />
      </Route>
    </Routes>
  )
}
export default EventPage

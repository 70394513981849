import React, { createContext, useEffect, useState } from 'react'
import { getSocketConn } from '../../utils'


export const SocketContext = createContext({
  socket: {} as any
})


const SocketProvider = ({ children }: any) => {

  const userId = Number(localStorage.getItem('user_id'))

  const [socket, setSocket] = useState<any>()

  // const socket = getSocketConn(userId)

  useEffect(() => {
    setSocket(getSocketConn(userId))
  }, [userId])

  const contextProvider: any = {
    socket
  }


  return <SocketContext.Provider value={contextProvider}>{children}</SocketContext.Provider>
}

export const SocketState = () => {
  return React.useContext(SocketContext)
}

export default SocketProvider
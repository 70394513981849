/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Projects} from './components/Projects'

const SessionPage: React.FC = () => {
  return (
    <div className={`card row pt-5`}>
      <Projects />
    </div>
  )
}

export {SessionPage}

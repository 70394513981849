
import React from 'react'
import { Card4 } from '../../content/cards/Card4'
import clsx from 'clsx'
import { useIntl } from 'react-intl'

type Props = {
    event_details: any
    gridbronzeTemplatecolumns: any
    gridTemplatecolumns: any
}

const Sponsers: React.FC<Props> = ({ event_details, gridbronzeTemplatecolumns, gridTemplatecolumns }) => {
    const intl = useIntl()
    return (
        <div
            className='shadow-xs card-rounded mx-15 mb-9 px-3 py-1 position-relative z-index-1 bg-body'
        >
            <div className={`card row`}>
                <div className='card-header card-title m-0 pt-10'>
                    <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: "RECEPTION.SPONSER" })}</h3>
                </div>
                {event_details?.event?.sponser_list?.gold?.length == 0 &&
                    event_details?.event?.sponser_list?.silver?.length == 0 &&
                    event_details?.event?.sponser_list?.bronze?.length == 0 && (
                        <div className='pt-10 pb-0 ps-9'>{intl.formatMessage({ id: "RECEPTION.SPONSER.NOT.FOUND" })}</div>
                    )}
                {/* begin::Body */}
                <div className='card-body pb-0'>
                    {event_details?.event?.sponser_list?.gold?.length > 0 && (
                        <>
                            <h5 className='mb-10 sponsor-area-gold'>{intl.formatMessage({ id: "RECEPTION.GOLD" })}</h5>
                            <div className={clsx('row g-6 mb-6 pb-6 w-auto h-325px sponser_ui', (event_details?.event?.sponser_list?.gold?.length > 2 || event_details?.event?.sponser_list?.gold?.length == 1) && "d-grid")}>
                                {event_details?.event?.sponser_list?.gold.map((g: any, i: number) => (
                                    <div
                                        className={clsx('mx-5 card shadow col-sm-12 col-md-3 col-xl-3 min-w-375px')}
                                        key={i}
                                    >
                                        <Card4
                                            icon={g?.image ? g?.image : '/media/avatars/blank.png'}
                                            title={g?.title}
                                            description={g?.website}
                                            class_name='symbol symbol-200px mb-6 w-100 mt-6'
                                        />
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                    {event_details?.event?.sponser_list?.silver?.length > 0 && (
                        <>
                            <h5 className='mb-5 sponsor-area-silver'>{intl.formatMessage({ id: "RECEPTION.SILVER" })}</h5>
                            <div
                                className={clsx('row g-6 mb-6 h-300px sponser_ui pb-6', (event_details?.event?.sponser_list?.silver?.length > 2 || event_details?.event?.sponser_list?.silver?.length == 1) && "d-grid")}
                                style={{
                                    gridTemplateColumns: gridTemplatecolumns,
                                }}
                            >
                                {event_details?.event?.sponser_list?.silver?.map((s: any, i: number) => (
                                    <div className='mx-5 card shadow col-sm-12 mb-5' key={i}>
                                        <Card4
                                            icon={s?.image ? s?.image : '/media/avatars/blank.png'}
                                            title={s?.title}
                                            description={s?.website}
                                            class_name='symbol symbol-auto mb-6 mt-6'
                                            image_class='h-150px w-100'
                                        />
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                    {event_details?.event?.sponser_list?.bronze?.length > 0 && (
                        <>
                            <h5 className='mb-5 mt-10 sponsor-area-bronze'>{intl.formatMessage({ id: "RECEPTION.BRONZE" })}</h5>
                            <div
                                className={clsx('row g-6 mb-6 mb-xl-9 sponser_ui pb-6 w-auto h-275px', (event_details?.event?.sponser_list?.bronze?.length > 2 || event_details?.event?.sponser_list?.bronze?.length == 1) && "d-grid")}
                                style={{ gridTemplateColumns: gridbronzeTemplatecolumns }}
                            >
                                {event_details?.event?.sponser_list?.bronze.map((b: any, i: number) => (
                                    <div className='mx-5 card shadow col-sm-12 ' key={i}>
                                        <Card4
                                            icon={b?.image ? b?.image : '/media/avatars/blank.png'}
                                            title={b?.title}
                                            description={b?.website}
                                            class_name='symbol symbol-80px mb-6 mt-6'
                                            image_class='h-100px w-100'
                                        />
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
                {/* end::Body */}
            </div>
        </div>)
}
export { Sponsers }

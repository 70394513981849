
import React from 'react'
import { Card8 } from '../../content/cards/Card8'
import { PaginationWidget } from '../../../../app/modules/widgets/pagination/PaginationWidget'
import { useIntl } from 'react-intl'

type Props = {
    list: any,
    page: any,
    setPage: any,
    totalPage: any,
    limit: any
}

const EventList: React.FC<Props> = ({ list, page, setPage, totalPage, limit }) => {
    const intl = useIntl()
    return (
        <div
            className='shadow-xs card-rounded mx-15 mb-9 px-3 py-1 position-relative z-index-1 bg-body'
        >
            <div className={`card row`}>
                <div className='card-header '>
                    <h3 className='fw-bolder card-title'>{intl.formatMessage({ id: "TEXT.EVENTS" })}</h3>
                </div>

                <div className='card-body row'>
                    {list && list?.events?.length > 0 ? (
                        <>
                            <>{list.events.map((l: any, i: number) => {
                                return (
                                    <div className='col-sm-12 col-md-4 col-xl-4 mb-7' key={i}>
                                        <Card8
                                            link={`/company-event/${l?.company_id}/${l?.id}`}
                                            icon={
                                                l?.thumbnail ? l?.thumbnail : 'media/svg/files/blank-image.svg'
                                            }
                                            title={l?.title}
                                            description={l?.description}
                                            startdatetime={l?.system_event_startdatetime}
                                            enddatettime={l?.system_event_enddatetime}
                                            location={l?.city}
                                            paid={l?.paid}
                                            record={l}
                                        />
                                    </div>
                                )
                            })}
                            </>
                            {list.total > 0 && list.total > limit && (
                                <PaginationWidget
                                    currentPage={page}
                                    setCurrentPage={setPage}
                                    totalPage={totalPage}
                                />
                            )}
                        </>
                    ) : (
                        <div className='card pl-3'>{intl.formatMessage({ id: "TEXT.EVENTS_NOT_FOUND" })}</div>
                    )}
                </div>
            </div>
        </div>)
}
export { EventList }

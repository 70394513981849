/**
 * Axios config  setup
 * Set interceptor for global api response error handling
 * Set access token in headers
 */
import axios from 'axios'

export const axiosInterceptor = () => {
  const token = localStorage.getItem('token')
  if (token) {
    axios.defaults.headers.common['authorization'] = `Bearer ${token}`
  } else {
    axios.defaults.headers.common['authorization'] = null
  }

  axios.interceptors.request.use(null, (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem('user_id')
        localStorage.removeItem('user_name')
        localStorage.removeItem('full_name')
        localStorage.removeItem('profile_pic')
        // window.location.href = process.env.REACT_APP_URL + 'error/401'
        return Promise.reject(error)
      } else return Promise.reject(error)
    } else if (error.request) {
      let err = {
        response: {
          data: {
            message: 'Something went wrong,Please try again later!!!',
          },
        },
      }
      return Promise.reject(err)
    }
  })

  axios.interceptors.response.use(null, (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem('user_id')
        localStorage.removeItem('user_name')
        localStorage.removeItem('profile_pic')
        // window.location.href = process.env.REACT_APP_URL + 'error/401'
        return Promise.reject(error)
      } else return Promise.reject(error)
    } else if (error.request) {
      let err = {
        response: {
          data: {
            message: 'Something went wrong,Please try again later!!!',
          },
        },
      }
      return Promise.reject(err)
    }
  })
}

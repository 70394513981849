import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {API_EVENT} from '../../constants/Api'

const initialState = {
  message: '',
  error: null,
  event: {},
}

export const event_detail: any = createAsyncThunk('event_detail', async (data: any) => {
  const response = await axios(API_EVENT + data.event_id)
  return response.data
})

// Reducer
const EventSlice = createSlice({
  name: 'event',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(event_detail.fulfilled, (state: any, action: any) => {
      state.event = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })
    builder.addCase(event_detail.rejected, (state: any, action: any) => {
      state.event = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })
  },
})

export default EventSlice.reducer

export const eventDetails = (state: any) => state.event.event

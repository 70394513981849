/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { useDispatch } from 'react-redux'
import { list_of_nominees, vote_now } from '../../../../store/Award/AwardSlice'
import { loaderChange } from '../../../../store/Loader/LoaderSlice'
import { useParams } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { ViewModal } from '../../modals/ViewModal/ViewModal'
import { useIntl } from 'react-intl'

type Props = {
  nominee_id: number
  icon: string
  nominee_name: string
  movie_name: string
  video: string
  progress: number
  disable: boolean
}

const Card7: FC<Props> = ({
  nominee_id,
  icon,
  nominee_name,
  movie_name,
  video,
  progress,
  disable
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { award_id, company_id } = useParams();
  const id = localStorage.getItem("event_id");
  const [image, setImage] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const voting_now = (nominee_id: any) => {
    setLoading(true)
    dispatch(vote_now({ award_id: award_id, nominee_id: nominee_id })).then((res: any) => {
      dispatch(list_of_nominees({ award_id: award_id }))
      setLoading(false)
      toastr.success(intl.formatMessage({ id: "TEXT.SUCCESS" }), intl.formatMessage({ id: "MSG.VOTE_ADD_SUCC" }))
    })
      .catch((err: any) => {
        console.log(err)
      })
  }


  return (<>
    <div
      className='card h-100 shadow border border-2 border-gray-300 border-hover-primary overlay-wrapper card-rounded'
    >
      <div className='card-header border-0 pt-9'>
        <div className='card-title m-0'>
          <div className='symbol symbol-100px w-100px bg-light me-2'>
            <img src={toAbsoluteUrl(icon)} alt='Card7' className='p-1 h-auto w-100' />
          </div>
          <div>
            <span className={`fs-3 fw-bolder text-dark me-auto px-4 py-2 m-0 `}>
              {nominee_name}
            </span>
            <div className='fw-bold text-gray-600 me-auto px-4 py-2 fs-5 m-0'>{movie_name}</div>
          </div>
        </div>
      </div>

      <div className='card-body p-9'>
        <div className='d-flex flex-wrap mb-5'>
          <button className='btn btn-sm btn-secondary me-4' onClick={() => {
            setModalShow(true)
            setImage(video);
          }}
          >{intl.formatMessage({ id: "TEXT.VIEW_IMG_VIDEO" })}</button>
          {!company_id && <button className='btn btn-sm btn-primary' onClick={() => voting_now(nominee_id)} disabled={disable}>
            {loading ?
              <>
                {intl.formatMessage({ id: "TEXT.VOTING_PROCC" })}
                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                </div>
              </>
              : intl.formatMessage({ id: "TEXT.VOTE_NOW" })}
          </button>}
        </div>

        <div
          className='h-4px w-100 bg-light mb-5 mt-10'
          data-bs-toggle='tooltip'
          title={`${progress}%`}
        >
          <div
            className={`bg-primary rounded h-4px`}
            role='progressbar'
            style={{ width: `${progress}%` }}
          ></div>
          <p className='text-gray-700 fs-6 mt-2'>{intl.formatMessage({ id: "TEXT.VOTING_PERCENTAGE" })} ({progress}%)</p>
        </div>
      </div>
    </div>
    <ViewModal image={image} modalShow={modalShow} setModalShow={setModalShow} /></>
  )
}

export { Card7 }

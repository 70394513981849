import { FC, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider, useLayout } from './core'
import { useLocation } from 'react-router-dom'
import { MenuComponent } from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import { WithChildren, toAbsoluteUrl } from '../helpers'
import { themeModeSwitchHelper, useThemeMode } from '../partials/layout/theme-mode/ThemeModeProvider'
import { HeaderWrapperForCompany } from './components/header/HeaderWrapperForCompany'
import { DefaultTitle } from './components/header/page-title/DefaultTitle'

const CompanyMasterLayout: FC<WithChildren> = ({ children }) => {
  const { classes } = useLayout()
  const { mode } = useThemeMode()
  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    themeModeSwitchHelper(mode)
  }, [mode])

  return (
    <PageDataProvider>
      <HeaderWrapperForCompany />
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid px-0 pt-80 mb-10' id='kt_wrapper'>        
         
          <Content>
            <div className='d-flex align-items-center mb-5 mt-5' id='kt_header_nav'>
              <DefaultTitle />
            </div>
            <Outlet />
          </Content>
        </div>
      </div>
      <Footer />
      <ScrollTop />
    </PageDataProvider>
  )
}

export { CompanyMasterLayout }

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { calculateTimeLeft, convertDatetime } from '../../../../utils'
import { useIntl } from 'react-intl'
const parse = require('html-react-parser')

type Props = {
  className: string
  name: string
  hosted_by: string
  image: string
  description: string
  msg: string
  startdatetime: string
  enddatetime: string
  isEnded: boolean
  event_id: string
}

const FeedsWidget5: React.FC<Props> = ({
  className,
  name,
  hosted_by,
  image,
  description,
  msg,
  startdatetime,
  enddatetime,
  isEnded,
  event_id,
}) => {
  const [timeLeft, setTimeLeft]: any = useState()
  const intl = useIntl()
  useEffect(() => {
    if (startdatetime && startdatetime != undefined && !isEnded) {
      setTimeLeft(calculateTimeLeft(startdatetime))
    }
    if (!isEnded) {
      const timer = setInterval(() => {
        if (startdatetime && startdatetime != undefined)
          setTimeLeft(calculateTimeLeft(startdatetime))
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    if (timeLeft && timeLeft?.trim() == '1 second') {
      window.location.href = `event/${event_id}/reception`
    }
  }, [timeLeft])

  return (
    <>
      <h1 className='text-gray-800 text-left fs-11 fw-bold'>
        {msg} {isEnded ? '' : `${timeLeft}`}
      </h1>
      <div className={`card ${className}`}>
        {/* begin::Body */}
        <div className='card-body pb-0'>
          {/* begin::Header */}
          <div className='d-flex align-items-center mb-5'>
            {/* begin::User */}

            <div className='d-flex align-items-center flex-grow-1'>
              {/* begin::Info */}
              <div className='d-flex flex-column'>
                <h2 className='text-gray-800 fs-11 fw-bold'>{name}</h2>
                <div className='fs-6 text-gray-500'>
                  {intl.formatMessage({ id: "TEXT.HOSTED_BY" })} <strong className='fw-bold'>{hosted_by}</strong>
                </div>
              </div>
              {/* end::Info */}
            </div>
            {/* end::User */}

            {/* begin::Menu */}
            <div className='my-0'></div>
            {/* end::Menu */}
          </div>
          {/* end::Header */}

          {/* begin::Post */}
          <div className='mb-5'>
            {/* begin::Image */}
            <div
              className='bgi-no-repeat bgi-size-cover rounded min-h-250px mb-5'
              style={{
                backgroundImage: `url('${toAbsoluteUrl(image)}')`,
              }}
            ></div>
            {/* end::Image */}

            <div className='text-gray-800 mb-5 d-flex mt-5'>
              <KTSVG path='/media/svg/event/calender-week.svg' className='svg-icon-3 me-3' />
              <p>
                {convertDatetime(startdatetime)} <b>{'  To  '}</b> {convertDatetime(enddatetime)}
              </p>
            </div>

            {/* begin::Text */}
            <div className='text-gray-800 mb-5'>{description ? parse(description) : ''}</div>
            {/* end::Text */}
          </div>
          {/* end::Post */}
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export { FeedsWidget5 }

import io from 'socket.io-client'
import Moment from 'react-moment'
import moment from 'moment'
import momentTz from 'moment-timezone'

// Set the output format for every react-moment instance.
Moment.globalFormat = 'MMM DD, YYYY hh:mm a'

// Set the default timezone for the application
if (localStorage.getItem('timezone')) {
  moment.tz.setDefault(localStorage.getItem('timezone'))
}

//To concate the path for the public folder
export const concatPublicUrl = (pathname) => process.env.PUBLIC_URL + pathname

export const getSocketConn = (user_id) => io(process.env.REACT_APP_CHAT_URL + '?user_id=' + user_id)

export const formatRelativeTime = (date) => {
  const now = moment() // Get the current time using Moment.js
  const duration = moment.duration(now.diff(date)) // Calculate the duration between the date and current time

  // Check if the duration is within 0 seconds (just now)
  if (duration.asSeconds() < 1) {
    return 'Just now'
  }

  // Check if the duration is within a minute
  if (duration.asMinutes() < 1) {
    return `${Math.floor(duration.asSeconds())}s`
  }

  // Check if the duration is within an hour
  if (duration.asHours() < 1) {
    return `${Math.floor(duration.asMinutes())}m`
  }

  // Check if the duration is within a day
  if (duration.asDays() < 1) {
    return `${Math.floor(duration.asHours())}h`
  }

  // Check if the duration is within a month
  if (duration.asMonths() < 1) {
    return `${Math.floor(duration.asDays())}d`
  }

  // Check if the duration is within a year
  if (duration.asYears() < 1) {
    return `${Math.floor(duration.asMonths())}mo`
  }

  // Otherwise, return the formatted date using React-moment
  return <Moment format='MMM D'>{date}</Moment>
}

export const calculateTimeLeft = (targetDate) => {
  const now =
    localStorage.getItem('timezone') == 'Asia/Calcutta' ? moment() : moment().tz('Asia/Kolkata')
  const target = moment(targetDate).tz('Asia/Kolkata').utc()
  const duration = moment.duration(target.diff(now)).subtract(5, 'hours').subtract(30, 'minutes')

  const years = Math.floor(duration.asYears())
  const months = duration.months()
  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  let monthString = months ? (months === 1 ? `${months} month` : `${months} months`) : ''
  let dayString = days ? (days === 1 ? `${days} day` : `${days} days`) : ''
  let hourString = hours ? (hours === 1 ? `${hours} hour` : `${hours} hours`) : ''
  let minuteString = minutes ? (minutes === 1 ? `${minutes} minute` : `${minutes} minutes`) : ''
  let secondString = seconds
    ? seconds === 1
      ? `${seconds} second`
      : `${seconds} seconds`
    : '1 seconds'

  return `${monthString} ${dayString} ${hourString} ${minuteString} ${secondString}`
}

export const convertDatetime = (datetime) => {
  let date = moment(datetime)
    .subtract(5, 'hours')
    .subtract(30, 'minutes')
    .tz(localStorage.getItem('timezone'))

  return (
    <Moment>
      {localStorage.getItem('timezone') == 'Asia/Calcutta'
        ? moment(datetime).tz('Asia/Kolkata').utc()
        : date}
    </Moment>
  )
}

export const convertToLocalTime = (datetime) => {
  let date = momentTz
    .utc(datetime)
    .subtract(5, 'hours')
    .subtract(30, 'minutes')
    .tz(localStorage.getItem('timezone'))

  return (
    <Moment format='hh:mm a'>
      {localStorage.getItem('timezone') == 'Asia/Calcutta' ? moment(datetime) : date}
    </Moment>
  )
}

export const getLastValue = (str, delimiter) => {
  let parts = str.split(delimiter)
  return parts[parts.length - 1]
}

export const generateRandomString = (length) => {
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length)
    result += charset.charAt(randomIndex)
  }
  return result
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { ChatState } from '../../../app/modules/Socket/ChatProvider'
import Moment from 'react-moment'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../helpers/components/KTSVG'

type Props = {
  isDrawer?: boolean
}

const ChatInner: FC<Props> = ({ isDrawer = false }) => {
  const divRef = useRef<HTMLDivElement>(null)
  const intl = useIntl()
  const [message, setMessage] = useState<string>('')
  const { sendMessage, chatHistory, selectedChatRoom, isGroup } = ChatState()
  const userId = localStorage.getItem('user_id')
  const userName = localStorage.getItem('user_name')
  const groupId = localStorage.getItem('groupId')

  const sendMessageFunc = () => {
    let chatid = isGroup ? groupId : selectedChatRoom?.id
    sendMessage(chatid, message, isGroup)
    setMessage('')
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessageFunc()
    }
  }

  useEffect(() => {
    divRef?.current && divRef.current.scrollTo({ top: 1000000 })
  }, [chatHistory])

  return (
    <>
      <div
        className='card-body'
        style={{ paddingBottom: '0px' }}
        id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
      >
        <div
          ref={divRef}
          style={{ minHeight: '535px' }}
          className={clsx('scroll-y me-n5 pe-5 h-lg-auto')}
          data-kt-element='messages'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies={
            isDrawer
              ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
              : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
          }
          data-kt-scroll-wrappers={
            isDrawer
              ? '#kt_drawer_chat_messenger_body'
              : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
          }
          data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
        >
          {chatHistory.length > 0 ? (
            <>
              {chatHistory.map((message: any) => {
                // const userInfo = userInfos[message.user]
                const state = Number(userId) !== Number(message?.sender) ? 'info' : 'primary'

                const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${Number(userId) !== Number(message?.sender) ? 'start' : 'end'
                  } mb-10`
                return (
                  <div
                    key={`message${message?.id}`}
                    className={clsx('d-flex', contentClass)}
                  >
                    <div
                      className={clsx(
                        'd-flex flex-column align-items',
                        `align-items-${Number(userId) !== Number(message?.sender) ? 'start' : 'end'
                        }`
                      )}
                    >
                      <div className='d-flex align-items-center mb-2'>
                        {Number(userId) !== Number(message?.sender) ? (
                          <>
                            <span className='cursor-pointer symbol symbol-35px'>
                              {message?.Event_Booking_Seat?.profile_pic ? (
                                <img src={message?.Event_Booking_Seat?.profile_pic} alt='no-img' />
                              ) : (
                                <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                                  {message?.Event_Booking_Seat?.name?.charAt(0).toUpperCase()}
                                </span>
                              )}
                            </span>
                            <div className='ms-3'>
                              <span className='fs-5 fw-bolder text-gray-900 me-1'>
                                {message?.Event_Booking_Seat?.name}
                              </span>
                              <span className='text-muted fs-8 mb-1'>
                                <Moment format='hh:mm a'>{message?.created_at}</Moment>
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className='me-3'>
                              <span className='text-muted fs-8 mb-1'>
                                <Moment format='hh:mm a'>{message?.created_at}</Moment>
                              </span>
                              <span className='fs-5 fw-bolder text-gray-900 me-1 ms-1'>
                                {/* {userName} */}
                                {intl.formatMessage({ id: "TEXT.YOU" })}
                              </span>
                            </div>
                            <span className='cursor-pointer symbol symbol-35px'>
                              {message?.Event_Booking_Seat?.profile_pic ? (
                                <img src={message?.Event_Booking_Seat?.profile_pic} alt='no-img' />
                              ) : (
                                <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                                  {userName?.charAt(0).toUpperCase()}
                                </span>
                              )}
                            </span>
                          </>
                        )}
                      </div>
                      <div
                        className={clsx(
                          'p-5 rounded',
                          `bg-light-${state}`,
                          'text-dark fw-bold mw-lg-400px',
                          `text-${Number(userId) !== Number(message?.sender) ? 'start' : 'end'}`
                        )}
                        data-kt-element='message-text'
                        dangerouslySetInnerHTML={{ __html: message?.content }}
                      ></div>
                    </div>
                  </div>
                )
              })}
            </>
          ) : (
            <div className='d-flex justify-content-center flex-column flex-center h-500px'>
              <div className='no-polls-logo'>
                <KTSVG path='/media/svg/event/chat-left.svg' className='svg-icon-2 message-logo' />
              </div>
              <h4 className='mt-2'>{intl.formatMessage({ id: "TEXT.NO_MSG_YET" })}</h4>
            </div>
          )}
        </div>

        <div
          className='card-footer pt-4 pb-4'
          id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
        >
          <textarea
            className='form-control mb-3'
            rows={2}
            data-kt-element='input'
            placeholder={intl.formatMessage({ id: "TEXT.TYPE_MESSAGE" })}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={onEnterPress}
          ></textarea>

          <div className='d-flex flex-stack'>
            <div className='d-flex align-items-center me-2'>
              {/* <button
                className='btn btn-sm btn-icon btn-active-light-primary me-1'
                type='button'
                data-bs-toggle='tooltip'
                title='Coming soon'
              >
                <i className='bi bi-paperclip fs-3'></i>
              </button>
              <button
                className='btn btn-sm btn-icon btn-active-light-primary me-1'
                type='button'
                data-bs-toggle='tooltip'
                title='Coming soon'
              >
                <i className='bi bi-upload fs-3'></i>
              </button> */}
            </div>
            <button
              className='btn btn-sm btn-primary'
              type='button'
              data-kt-element='send'
              onClick={sendMessageFunc}
            >
              {intl.formatMessage({ id: "TEXT.SEND" })}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export { ChatInner }

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../helpers'
import { ChatInner } from '../../chat/ChatInner'
import { ChatState } from '../../../../app/modules/Socket/ChatProvider'
import { generateRandomString } from '../../../../utils'

const DrawerMessenger: FC = () => {
  const { selectedChatRoom, isGroup, sendMessage } = ChatState()
  const userId = Number(localStorage.getItem('user_id'))
  const eventName = localStorage.getItem('event_name')
  const [user, setUser] = useState<any>([])

  useEffect(() => {
    if (!selectedChatRoom && selectedChatRoom?.users?.length === 0) return
    setUser(selectedChatRoom?.users?.filter((user: any) => user.id !== userId))
  }, [selectedChatRoom])

  const openChatList = () => {
    // chat_list_open show
    setTimeout(() => {
      const ele = document.getElementById('chat_list_box')
      const ele2 = document.getElementById('kt_header_search')
      const ele3 = document.getElementById('kt_header_search_toggle')
      if (!ele || !ele2) return
      ele?.classList.add('show', 'for_manual_close')
      ele2?.classList.add('show', 'menu-dropdown')
      ele3?.classList.add('show', 'active')
      ele.style.cssText = `
        z-index: 105;
        position: fixed;
        inset: 0px 0px auto auto;
        margin: 0px;
        transform: translate3d(-129.6px, 52.8px, 0px);
      `
    }, 500)
  }

  return (
    <>
      <div
        id='kt_drawer_chat'
        className='bg-body'
        data-kt-drawer='true'
        data-kt-drawer-name='chat'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'300px', 'md': '500px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_drawer_chat_toggle'
        data-kt-drawer-close='#kt_drawer_chat_close'
      >
        <div className='card w-100 rounded-0' id='kt_drawer_chat_messenger'>
          <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
            <div className='card-title'>
              <div className='d-flex justify-content-center flex-row align-items-baseline me-3'>
                <div
                  className='me-3 btn btn-sm btn-icon btn-active-light-primary'
                  id='kt_drawer_chat_close'
                >
                  <KTSVG path='/media/svg/event/arrow-left.svg' className='svg-icon-3' aria-hidden='true'></KTSVG>
                </div>
                <div className='fs-4 fw-bolder text-gray-900 lh-1'>
                  {!isGroup ? (
                    <div className='menu-content d-flex align-items-center px-2'>
                      <div className='cursor-pointer symbol symbol-45px me-5'>
                        {user && user?.length > 0 && user[0]?.profile_pic ? (
                          <img
                            src={user && user?.length > 0 && user[0]?.profile_pic}
                            alt='no-img'
                          />
                        ) : (
                          <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                            {user && user?.length > 0 && user[0]?.name?.charAt(0)?.toUpperCase()}
                          </span>
                        )}
                      </div>

                      <div className='d-flex flex-column'>
                        <div className='fw-bolder d-flex align-items-center fs-5 mb-2'>
                          {(user && user?.length > 0 && user[0]?.name) || ''}
                        </div>
                        <a
                          href={user && user?.length > 0 ? 'mailto:' + user[0]?.email : '#'}
                          className='fw-bold text-muted text-hover-primary fs-7'
                        >
                          {(user && user?.length > 0 && user[0]?.email) || ''}
                        </a>
                      </div>
                    </div>
                  ) : (
                    `${eventName ? eventName : 'Group Name'}`
                  )}
                </div>

                {/* <div className='mb-0 lh-1'>
                  <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                  <span className='fs-7 fw-bold text-gray-400'>Active</span>
                </div> */}
              </div>
            </div>

            <div className='card-toolbar'>
              {/* {!selectedChatRoom?.isOnGoingcall && ( */}
              <>
                {!isGroup && (
                  // <button
                  //   className='btn btn-sm btn-icon btn-active-light-primary me-2'
                  //   data-bs-toggle='modal'
                  //   data-bs-target='#kt_modal_create_app'
                  //   onClick={() => {
                  //     var meeting_string = generateRandomString(7);
                  //     sendMessage(selectedChatRoom?.id, `Hi ${(user && user?.length > 0 && user[0]?.name) || ''}, i'm invite you to join video meeting. please join using this url: <a  href='http://localhost:3013/event/90/meeting/${meeting_string}'>http://localhost:3013/event/90/meeting/${meeting_string}</a>`, isGroup)
                  //   }}
                  // >
                  //   <i className='fa-solid fa-video fs-2'></i>
                  // </button>
                  <></>
                )}
              </>
              {/* )} */}
              {/* <div className='me-2'>
                <button
                  className='btn btn-sm btn-icon btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <i className='bi bi-three-dots fs-3'></i>
                </button>
              </div> */}

              <div
                className='btn btn-sm btn-icon btn-active-light-primary'
                id='kt_drawer_chat_close'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
              </div>
            </div>
          </div>

          <ChatInner isDrawer={true} />
        </div>
      </div>
    </>
  )
}

export { DrawerMessenger }

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isLoading: false
}

export const loaderSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    loaderChange: (state, action) => {
      state.isLoading = action.payload
    }
  }
})

export const { loaderChange } = loaderSlice.actions
export default loaderSlice.reducer


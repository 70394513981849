import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { livestageDetails, livestage_detail } from '../../../../store/LiveStage/LiveStageSlice'
import ReactPlayer from 'react-player'
import { loaderChange } from '../../../../store/Loader/LoaderSlice'
import { getLastValue } from '../../../../utils'


const LiveStage: React.FC = () => {
  const { livestage_id } = useParams()
  const dispatch = useDispatch()
  const livestage = useSelector(livestageDetails)

  useEffect(() => {
    dispatch(loaderChange(true))
    if (livestage_id) {
      dispatch(livestage_detail({ livestage_id: livestage_id })).then(() =>
        dispatch(loaderChange(false))
      )
    }
  }, [])

  return (
    <>
      {livestage?.stream_provider == "youtube" &&
        <ReactPlayer url={process.env.REACT_APP_YOUTUBE_URL + `${livestage?.stream_url}`} controls={true}
          className="h-100 bgi-no-repeat bgi-position-center bgi-size-cover w-100" />
      }

      {livestage?.stream_provider == "bananry" &&
        <ReactPlayer url={process.env.REACT_APP_BANANRY_URL1 + `${getLastValue(livestage?.stream_url, "/")}` + process.env.REACT_APP_BANANRY_URL2} controls={true}
          className="h-100 bgi-no-repeat bgi-position-center bgi-size-cover w-100" />
      }

    </>
  )
}

export { LiveStage }

import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {API_COMPANY_DETAILS, API_COMPANY_EVENT_LISTS} from '../../constants/Api'

const initialState = {
  message: '',
  error: null,
  companyDetails: null,
  companyList: {total: 0, events: []},
}

export const companyDetailsAction: any = createAsyncThunk('company_Details', async (data: any) => {
  return await axios
    .post(API_COMPANY_DETAILS, data)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      console.log(err)
      return err
    })
})

export const companyEventListAction: any = createAsyncThunk(
  'company_event_list',
  async (data: any) => {
    return await axios
      .post(API_COMPANY_EVENT_LISTS, data)
      .then((response: any) => {
        return response.data
      })
      .catch((err) => {
        console.log(err)
        return err
      })
  }
)

// Reducer
const companySlice = createSlice({
  name: 'company',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //companydetails
    builder.addCase(companyDetailsAction.fulfilled, (state, action: any) => {
      let data = action.payload?.data
      state.companyDetails = data
      state.message = action.payload?.message
      state.error = null
    })

    builder.addCase(companyDetailsAction.rejected, (state, action: any) => {
      state.companyDetails = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })

    // company list
    builder.addCase(companyEventListAction.fulfilled, (state, action: any) => {
      state.companyList = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })

    builder.addCase(companyEventListAction.rejected, (state, action: any) => {
      state.companyList = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })
  },
})

export default companySlice.reducer
export const companyDetails = (state: any) => state.company.companyDetails
export const companyEventList = (state: any) => state.company.companyList

import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'

import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import { ThemeModeProvider } from './_metronic/partials/layout/theme-mode/ThemeModeProvider'
//Axios Interceptor
import { axiosInterceptor } from './middleware'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import { Provider as ReactReduxProvider } from 'react-redux'
import store from './store'
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'

axiosInterceptor()
const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <ReactReduxProvider store={store}>
          <ThemeModeProvider>
            <AppRoutes />
          </ThemeModeProvider>
        </ReactReduxProvider>
      </MetronicI18nProvider>
    </QueryClientProvider>
  )
}

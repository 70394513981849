/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { livestageList, livestage_by_event } from '../../../store/LiveStage/LiveStageSlice'
import { Card2 } from '../../../_metronic/partials/content/cards/Card2'
import { loaderChange } from '../../../store/Loader/LoaderSlice'
import {useIntl} from 'react-intl'
import { convertToLocalTime } from '../../../utils'
import moment from 'moment'

const LiveStageLists: React.FC = () => {
  const id = localStorage.getItem("event_id");
  const dispatch = useDispatch()
  const livestages = useSelector(livestageList)
  const intl = useIntl()


  useEffect(() => {
    dispatch(loaderChange(true))
    dispatch(livestage_by_event({ event_id: id })).then((res: any) => {
      dispatch(loaderChange(false))
    })
      .catch((err: any) => {
        dispatch(loaderChange(false))
      })
  }, [])

  return (
    <div className={`card row pt-5`}>
      <div className='card-header '>
        <h3 className='fw-bolder card-title'>{intl.formatMessage({id: 'LIVESTAGE.LIVESTAGES'})}</h3>
      </div>

      <div className='card-body row'>
        {livestages && livestages.length > 0 ? (
          livestages.map((livestage: any, i: number) => {
            return (
              <div className='col-sm-12 col-md-4 col-xl-4 mb-7' key={i}>
                <Card2
                  link={`/event/${id}/live-stages/${livestage.livestage_id}`}
                  // link="#"
                  icon={
                    livestage?.thumbnail ? livestage?.thumbnail : 'media/svg/files/blank-image.svg'
                  }
                  title={livestage?.title}
                  description={''}
                  users={livestage}
                  totalView={1}
                  startdatetime={livestage?.startdatetime}
                  enddatettime={livestage?.enddatettime}
                />
              </div>
            )
          })
        ) : (
          <div className='card pl-3'>{intl.formatMessage({id: 'LIVESTAGE.NOT.FOUND'})}</div>
        )}
      </div>
    </div>
  )
}

export { LiveStageLists }

import React, { createContext, useState, useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { SocketState } from './SocketProvider'

export const PollContext = createContext({
  userId: {},
  socket: {} as any,
  poll: {
    pollList: [],
    isLoading: false,
    pollTab:'poll_list'
  },
  createPoll: (question: string, options: string[]) => { },
  submitPolls: (optionId: number) => { },
  sharePoll: (pollId: number, type: any) => { },
  deletePoll: (pollId: number) => { },
  dispatchPoll: ({type, payload}: any) => {},
})

const POLL_LIST_ACTIONS = {
  POLL_LIST: 'POLL_LIST',
  POLL_TAB: 'POLL_TAB',
}

const pollListReducer = (state: any, { type, payload }: any) => {
  switch (type) {
    case POLL_LIST_ACTIONS.POLL_LIST: {
      return {
        ...state,
        pollList: [...payload],
      }
    }
    case POLL_LIST_ACTIONS.POLL_TAB: {
      return {
        ...state,
        pollTab:payload,
      }
    }
    default:
      return state
  }
}

const PollProvider = ({ children }: any) => {
  const userId = Number(localStorage.getItem('user_id'))
  const event = Number(localStorage.getItem('event_id'))
  const user_type = localStorage.getItem('user_type')
  const room = localStorage.getItem('room')
  const groupId = localStorage.getItem('groupId') as string


  const [poll, dispatchPoll] = useReducer(pollListReducer, {
    pollList: [],
    isLoading: false,
    pollTab:'poll_list'
  })

  const { socket } = SocketState()

  const createPoll = (question: string, options: string[]) => {
    const pollData = {
      question: question,
      user_id: userId,
      event_id: event,
      options: options,
    }

    socket.emit('createPoll', pollData)
  }

  const getPolls = () => {
    const data = {
      event_id: event,
      user_type: user_type,
      user_id: Number(userId),
      type: poll.pollTab
    }

    socket.emit('getResult', data)
  }

  const getPollNotificationList = () => {
    socket.emit('get_notification_list', {
      user_id: userId,
      page: 1,
      limit: 300,
      notification_type: 'poll',
    })
  }

  const getPollsReceive = () => {
    //
    socket.on('get_poll_result_recive', (data: any) => {
      dispatchPoll({
        type: POLL_LIST_ACTIONS.POLL_LIST,
        payload: data,
      })
    })
  }

  const submitPolls = (optionId: number) => {
    socket.emit('submitPoll', {
      option_id: optionId,
      event_id: event,
      user_id: Number(userId),
    })
  }

  const sharePoll = (pollId: number, type: any) => {
    socket.emit('sharePoll', {
      event_id: event,
      poll_id: pollId,
      user_id: Number(localStorage.getItem('user_id')),
      type
    })
  }

  const deletePoll = (pollId: number) => {
    socket.emit('deletePoll', {
      poll_id: pollId,
      chat_id: Number(groupId),
    })
  }

  useEffect(() => {
    if (socket) {
      getPolls()

      // getPollsReceive()

      socket.on('create_poll_recive', (data: any) => {
        getPolls()
      })

      socket.on('submit_poll_recive', () => {
        getPolls()
      })

      socket.on('delete_poll_recive', () => {
        getPolls()
      })

      socket.on('share_poll_succ', () => {
        getPolls()
        getPollNotificationList()
      })
    }
  }, [socket])

  useEffect(() => {
    if (!socket) return
    socket.on('get_poll_result_recive', (data: any) => {
      dispatchPoll({
        type: POLL_LIST_ACTIONS.POLL_LIST,
        payload: data,
      })
    })
  }, [socket])

  const contextProvider: any = {
    userId,
    socket,
    poll,
    createPoll,
    submitPolls,
    deletePoll,
    sharePoll,
    dispatchPoll
  }

  return <PollContext.Provider value={contextProvider}>{children}</PollContext.Provider>
}

export const PollState = () => {
  return React.useContext(PollContext)
}

export default PollProvider

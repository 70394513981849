/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'react-bootstrap'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {PollState} from '../../../../app/modules/Socket/PollProvider'

interface Props {
  modal: boolean
  setModal: (prev: boolean) => void
}

const CreatePoll: FC<Props> = ({modal, setModal}) => {
  const {createPoll} = PollState()
  const [pollState, setPollState] = useState({
    question: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
  })
  const [error, setError] = useState(false)
  const inputChange = (e: any) => {
    setError(false)
    setPollState((prev) => ({...prev, [e.target.name]: e.target.value}))
  }

  const createPollFunc = () => {
    if (!pollState.question || !pollState.option1 || !pollState.option2) {
      setError(true)
    } else {
      createPoll(
        pollState.question,
        [pollState.option1, pollState.option2, pollState.option3, pollState.option4].filter(
          (poll) => poll != ''
        )
      )
      const modalElement = document.getElementById('kt_modal_poll')
      setPollState({question: '', option1: '', option2: '', option3: '', option4: ''})
      setModal(false)
    }
  }

  return (
    <Modal show={modal} centered className='fade' onHide={() => setModal(false)}>
      <ModalHeader>
        <div className='text-center w-100'>
          <div className='d-flex justify-content-between'>
            <h2 className='m-auto'>Create Poll</h2>
            <h3 style={{cursor: 'pointer'}} onClick={() => setModal(false)}>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </h3>
          </div>
        </div>
      </ModalHeader>
      <ModalBody className='p-8'>
        {error && (
          <div className='text-center'>
            <div className='text-danger fw-bold fs-5'>Please enter all details.</div>
          </div>
        )}

        <div className='mb-3'>
          <label className='fw-bold fs-5'>
            Question <span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            name='question'
            value={pollState.question}
            onChange={inputChange}
            className='form-control form-control-solid mb-4'
            placeholder='Enter Question'
          />
        </div>
        <div className='mb-3'>
          <label className='fw-bold fs-5'>
            Option 1 <span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            name='option1'
            value={pollState.option1}
            onChange={inputChange}
            className='form-control form-control-solid mb-4'
            placeholder='Enter Option1'
          />
        </div>
        <div className='mb-3'>
          <label className='fw-bold fs-5'>
            Option 2 <span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            name='option2'
            value={pollState.option2}
            onChange={inputChange}
            className='form-control form-control-solid mb-4'
            placeholder='Enter Option2'
          />
        </div>
        <div className='mb-3'>
          <label className='fw-bold fs-5'>Option 3</label>
          <input
            type='text'
            name='option3'
            value={pollState.option3}
            onChange={inputChange}
            className='form-control form-control-solid mb-4'
            placeholder='Enter Option3'
          />
        </div>

        <div className='mb-3'>
          <label className='fw-bold fs-5'>Option 4</label>
          <input
            type='text'
            name='option4'
            value={pollState.option4}
            onChange={inputChange}
            className='form-control form-control-solid mb-4'
            placeholder='Enter Option 4'
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className='text-end'>
          <button className='btn btn-primary' onClick={createPollFunc}>
            Submit
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export {CreatePoll}

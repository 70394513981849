/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { boothList, boothListAction } from '../../../store/Booth/BoothSlice'
import { loaderChange } from '../../../store/Loader/LoaderSlice'
import { Card6 } from '../../../_metronic/partials/content/cards/Card6'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../_metronic/helpers/components/KTSVG'

type sizeObjectType = {
  large: string
  small: string
  medium: string
}

const sizeOfBooth: sizeObjectType = {
  large: '80%',
  small: '50%',
  medium: '60%',
}

const BoothListPage: React.FC = () => {
  const intl = useIntl()
  const boothListData = useSelector(boothList)
  const dispatch = useDispatch()
  const id = localStorage.getItem("event_id");

  useEffect(() => {
    dispatch(loaderChange(true))
    dispatch(boothListAction(id))
      .then((res: any) => {
        dispatch(loaderChange(false))
      })
      .catch((err: any) => {
        dispatch(loaderChange(false))
      })
  }, [])

  let gridTemplatecolumns = ''
  for (
    let index = 0;
    index < boothListData?.length &&
    boothListData.filter((booth: any) => booth.size === 'medium').length;
    index++
  ) {
    gridTemplatecolumns += ' 325px'
  }
  let gridbronzeTemplatecolumns = ''
  for (
    let index = 0;
    index < boothListData?.length &&
    boothListData.filter((booth: any) => booth.size === 'small').length;
    index++
  ) {
    gridbronzeTemplatecolumns += ' 275px'
  }
  return (
    <div className={`card row pt-5 pb-5`}>
      <div className='card-header card-title m-0 pt-5'>
        <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: "TEXT.BOOTHLIST" })}</h3>
      </div>
      {/* begin::Body */}
      <div className='card-body pb-0'>
        {/* begin::Post */}
        {boothListData?.length > 0 ? (
          <>
            <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
              {boothListData
                .filter((booth: any) => booth.size === 'large')
                .map((g: any, i: number) => (
                  <div
                    className={clsx('mx-5 card shadow col-sm-12 col-md-4 col-xl-4 min-w-375px')}
                    key={g.id}
                  >
                    <Card6
                      icon={g?.image ? g?.image : '/media/avatars/blank.png'}
                      title={g?.name}
                      link={g?.id}
                      description={g?.headline}
                      class_name='symbol symbol-150px mb-6'
                    />
                  </div>
                ))}
              {boothListData
                .filter((booth: any) => booth.size === 'large').length > 0 && <hr />}
            </div>
            <div
              className='row g-xl-9 mb-6 sponser_ui pb-6 '
              style={{
                gridTemplateColumns: gridTemplatecolumns,
              }}
            >
              {boothListData
                .filter((booth: any) => booth.size === 'medium')
                .map((g: any, i: number) => (
                  <div className='mx-5 card shadow col-sm-12 mb-5' key={g.id}>
                    <Card6
                      icon={g?.image ? g?.image : '/media/avatars/blank.png'}
                      title={g?.name}
                      link={g?.id}
                      description={g?.headline}
                      class_name='symbol symbol-150px mb-6'
                    />
                  </div>
                ))}
            </div>
            {boothListData
              .filter((booth: any) => booth.size === 'medium').length > 0 && <hr />}
            <div
              className='row g-6 mb-6 mb-xl-9 sponser_ui pb-6 w-auto'
              style={{ gridTemplateColumns: gridbronzeTemplatecolumns }}
            >
              {boothListData
                .filter((booth: any) => booth.size === 'small')
                .map((g: any, i: number) => (
                  <div className='mx-5 card shadow col-sm-12 ' key={g.id}>
                    <Card6
                      icon={g?.image ? g?.image : '/media/avatars/blank.png'}
                      title={g?.name}
                      link={g?.id}
                      description={g?.headline}
                      class_name='symbol symbol-150px mb-6'
                    />
                  </div>
                ))}
            </div>
          </>
        ) : (
          <div className='d-flex justify-content-center flex-column flex-center h-500px'>
            <div className='no-polls-logo'>
              <KTSVG path='/media/svg/event/shop-window.svg' className='svg-icon-2x message-logo' />
            </div>
            <h4 className='mt-2'>{intl.formatMessage({ id: "TEXT.NO_BOOTHS" })}</h4>
          </div>
        )}
        {/* end::Post */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { BoothListPage }

import React, { createContext, useState, useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { SocketState } from './SocketProvider'

export const ChatContext = createContext({
  userId: {},
  socket: {} as any,
  activeRoomIds: '',
  receivedMessage: {},
  chatHistory: null as any,
  groupUsers: null as any,
  selectedChatRoom: {} as any,
  CHATLIST_ACTIONS: {} as any,
  chatList: {
    chatList: [],
    groupChatList: [],
    notificationList: [] as any,
    isLoading: false,
    // pollList: [],
  },
  isGroup: false as boolean,
  pollNotificationCount: 0,
  privateChatNotificationCount: 0,
  groupChatNotificationCount: 0,
  setSelectedChatRoom: (values: any) => { },
  setChatHistory: (values: any) => { },
  dispatchChatList: ({ type, payload }: any) => { },
  switchRooms: (values: any) => { },
  handleGroupChatListChange: (message: string) => { },
  handleChange: (message: string) => { },
  createPrivateChat: (otherUserId: number) => { },
  sendMessage: (chat_id: string, message: string, isGroup: any) => { },
  joinCall: (chatId: any) => { },
  setIsGroup: (isGroup: any) => { },
  getMessages: (chatId: any) => { },
  readBy: (chatID: number, senderId: number) => { },
  // createPoll: (question: string, options: string[]) => { },
})

const CHATLIST_ACTIONS = {
  SET_MORECHATS: 'SET_MORECHATS',
  SET_GROUP_CHAT_LIST: 'SET_GROUP_CHAT_LIST',
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  // POLL_LIST: 'POLL_LIST',
}

const chatListReducer = (state: any, { type, payload }: any) => {
  switch (type) {
    case CHATLIST_ACTIONS.SET_MORECHATS: {
      return {
        ...state,
        chatList: [...payload],
      }
    }

    case CHATLIST_ACTIONS.SET_GROUP_CHAT_LIST: {
      return {
        ...state,
        groupChatList: [...payload],
      }
    }
    case CHATLIST_ACTIONS.GET_NOTIFICATIONS: {
      return {
        ...state,
        notificationList: payload,
      }
    }
    // case CHATLIST_ACTIONS.POLL_LIST: {
    //   return {
    //     ...state,
    //     pollList: [...payload],
    //   }
    // }
    default:
      return state
  }
}

const ChatProvider = ({ children }: any) => {
  const userId = Number(localStorage.getItem('user_id'))
  const event = Number(localStorage.getItem('event_id'))
  const room = localStorage.getItem('room')
  const user_type = localStorage.getItem('user_type')
  const groupId = localStorage.getItem('groupId') as string
  const [chatList, dispatchChatList] = useReducer(chatListReducer, {
    chatList: [],
    groupChatList: [],
    isLoading: false,
    notificationList: [],
    // pollList: [],
  })

  const { socket } = SocketState()
  const [activeRoomId, setActiveRoomId] = useState('')
  const [receivedMessage, setReceivedMessage] = useState<any>()
  const [selectedChatRoom, setSelectedChatRoom] = useState<any>()
  const [chatHistory, setChatHistory] = useState<any>([])
  const [pollNotificationCount, setPollNotificationCount] = useState<any>()
  const [privateChatNotificationCount, setPrivateChatNotificationCount] = useState<any>()
  const [groupChatNotificationCount, setGroupChatNotificationCount] = useState<any>()

  const [isGroup, setIsGroup] = useState(false)
  const [limit, setLimit] = useState(30)

  let chatlist = (userId: number, eventId: number, search: string) => {
    let params = { user_id: userId, event_id: eventId, search: search }
    socket.emit('get_join_user_list', params)
  }

  let readBy = (chatID: number, senderId: number) => {
    socket.emit('read_by', { chat_id: chatID, sender_user_id: senderId })
  }

  const switchRooms = (values: any) => {
    setActiveRoomId(values)
    setReceivedMessage({})
  }

  const sendMessage = (chat_id: string, messageContent: string, isGroup: any) => {
    if (messageContent && messageContent.trim().length > 0) {
      let newMessage = {
        chat_id: Number(chat_id),
        user_id: userId,
        content: messageContent,
        isGroup: isGroup,
      }

      socket.emit('sendMessage', newMessage)
    }
  }

  const createPrivateChat = (otherUserId: number) => {
    const sendData = {
      users: `${otherUserId},${userId}`,
      is_group_chat: false,
      created_by: Number(userId),
      event_id: Number(event),
      user_id: Number(userId),
    }

    socket.emit('create_chat', sendData)
  }

  const getMessages = (chatId: string) => {
    if (chatId) {
      socket.emit('getMessages', { chat_id: Number(chatId), page: 1, limit: limit })
    }
  }

  const handleChange = async (target: any) => {
    chatlist(userId, event, target)
  }

  const joinCall = (chatId: any) => {
    const sendData = {
      user_id: userId,
      chat_id: chatId,
      'type:': 0,
      isGroup: 0,
    }

    socket.emit('join_call', sendData)
  }

  const getPollNotificationList = () => {
    socket.emit('get_notification_list', {
      user_id: userId,
      page: 1,
      limit: limit,
      notification_type: 'poll',
    })
  }

  const getGeneralNotificationList = () => {
    socket.emit('get_unread_count', { event_id: event })
  }

  // const createPoll = (question: string, options: string[]) => {
  //   const pollData = {
  //     "quetion": question,
  //     "user_id": userId,
  //     "event_id": event,
  //     "options": options
  //   }

  //   socket.emit('createPoll', pollData)
  // }

  const getPolls = () => {
    socket.emit('getResult', {
      event_id: event,
      user_type: user_type,
      user_id: Number(userId),
    })
  }

  // const getPollsReceive = () => {

  //   socket.on('get_poll_recive', (data: any) => {

  //     dispatchChatList({
  //       type: CHATLIST_ACTIONS.POLL_LIST,
  //       payload: data,
  //     })
  //   })
  // }

  // const submitPolls = (optionId: number) => {
  //   socket.emit('submitPoll', {
  //     "option_id": optionId
  //   })
  // }

  // const getResult = () => {
  //   socket.emit('getResult', {
  //     "event_id": event,
  //   })
  // }

  useEffect(() => {
    if (userId && socket) {
      socket.on('connect', () => {
        console.log('socket connected successfully')
        if (socket.connected) {
          // code for join user in group chat
          socket.emit('join_event', {
            event_id: Number(event),
            user_id: Number(userId),
            chat_id: Number(localStorage.getItem('groupId')),
            roomname: room,
          })
        }
      })
    }
    return () => {
      if (!socket) return
      // socket.emit('disconnect_user', { user_id: userId })
    }
  }, [userId, socket])

  useEffect(() => {
    if (!socket) return
    if (isGroup) {
      getMessages(groupId)
    } else if (selectedChatRoom && Object.values(selectedChatRoom).length && selectedChatRoom?.id) {
      getMessages(selectedChatRoom?.id)
    }
  }, [selectedChatRoom?.id, isGroup])

  useEffect(() => {
    if (!socket) return
    getPollNotificationList()
    getGeneralNotificationList()
    // getPolls()
    // getPollsReceive()

    // socket.on('submit_poll_recive', () => {
    //   getPolls()
    // })

    // socket.on('get_poll_result_recive', () => {
    //   getPolls()
    // })
    // socket.on('user_disconnected_success', () => { })

    socket.on('get_notification_list_succ', (data: any) => {
      // console.log("get_notification_list_succ", data)
      dispatchChatList({
        type: CHATLIST_ACTIONS.GET_NOTIFICATIONS,
        payload: data,
      })

      if (data.notification_type === 'general') {
        setPrivateChatNotificationCount(data.unread_count)
      }
      if (data.notification_type === 'poll') {
        setPollNotificationCount(data.unread_count)
      }
    })

    socket.on('get_unread_count_succ', (data: any) => {
      // console.log("get_unread_count_succ", data)
      setPrivateChatNotificationCount(data.private_unread_count.unread_count)
      setGroupChatNotificationCount(data.group_unread_count.unread_count)
    })

    socket.on('receive_notification', () => {
      // getPollNotificationList()
      getGeneralNotificationList()
    })

    socket.on('read_notifications_succ', (data: any) => {
      getPollNotificationList()
      getGeneralNotificationList()
    })

    socket.on('join_event_receive', () => {
      chatlist(userId, event, '')
      getPolls()
    })

    chatListAction()

    socket.on('receive_get_messages', (data: any) => {
      setChatHistory(data)
      // getPollNotificationList()
      // getGeneralNotificationList()
    })

    socket.on('join-call-recive', () => { })

    socket.on('read_by_success', (data: any) => {
      getGeneralNotificationList()
      chatlist(userId, event, '')
    })

    socket.on('group_read_by_succ', (data: any) => {
      getGeneralNotificationList()
    })
  }, [socket])

  useEffect(() => {
    if (!socket) return
    socket.on('create_chat_recive', (data: any) => {
      const localRoom = localStorage.getItem('roomId') as string
      setSelectedChatRoom(data)
      const roomId = localRoom ? JSON.parse(localRoom) : null
      localStorage.setItem('roomId', JSON.stringify(data))
      // setChatHistory([])
      getMessages(data?.id)
    })
  }, [socket])

  useEffect(() => {
    if (!socket) return
    socket.on('receive_message', (data: any) => {
      console.log("receive_message", data)
      const isGroupOpen = localStorage.getItem('isGroupOpen')

      const localRoom = localStorage.getItem('roomId') as string
      chatlist(userId, event, '')
      const roomId = localRoom ? JSON.parse(localRoom) : null
      if (isGroupOpen !== 'true') {
        if (Number(roomId?.id) !== data[0].chat) return
      }
      setChatHistory((prev: any) => [
        ...prev,
        prev.findIndex((chat: any) => chat.id === data[0].id) === -1 ? data[0] : null,
      ])
      // if (!isGroup) {
      //   const senderId =
      //     roomId?.users[0].id === Number(userId) ? roomId?.users[1]?.id : roomId?.users[0]?.id
      //   setTimeout(() => {
      //     readBy(Number(userId), data[0].chat, senderId)
      //   }, 2000)
      // }
      getGeneralNotificationList()
    })
  }, [socket])

  const chatListAction = () => {
    socket.on('get_join_user_list_succ', (data: any) => {
      // console.log("get_join_user_list_succ", data)
      dispatchChatList({
        type: CHATLIST_ACTIONS.SET_MORECHATS,
        payload: data,
      })
    })
  }

  const contextProvider: any = {
    userId,
    socket,
    activeRoomId,
    receivedMessage,
    CHATLIST_ACTIONS,
    chatList,
    selectedChatRoom,
    chatHistory,
    isGroup,
    pollNotificationCount,
    privateChatNotificationCount,
    groupChatNotificationCount,
    createPrivateChat,
    setChatHistory,
    setSelectedChatRoom,
    dispatchChatList,
    switchRooms,
    sendMessage,
    handleChange,
    joinCall,
    setIsGroup,
    getMessages,
    readBy,
  }

  return <ChatContext.Provider value={contextProvider}>{children}</ChatContext.Provider>
}

export const ChatState = () => {
  return React.useContext(ChatContext)
}

export default ChatProvider

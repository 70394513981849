/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

const HeaderUserMenu: FC = () => {
  const intl = useIntl()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='no-img' src={localStorage.getItem('profile_pic') || ''} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {localStorage?.getItem('user_name')}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <a
              href="#"
              className='fw-bold text-muted text-hover-primary fs-7'
            >
              {localStorage?.getItem('email')}
            </a>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/account/complaint-form'} className='menu-link px-5'>
          {intl.formatMessage({ id: "MENU.RAISE_COMPLAIN" })}
        </Link>
      </div>

      {/* <div className='menu-item px-5'>
        <a className='menu-link px-5'>Sign Out</a>
      </div> */}
    </div>
  )
}

export { HeaderUserMenu }

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import parse from 'html-react-parser'
import { useIntl } from 'react-intl'

type Props = {
  image: string
  title: string
  description: string
  category: string
  progress: number
  link: string
}

const Card5: FC<Props> = ({
  image,
  title,
  description,
  category,
  progress,
  link
}) => {
  const intl = useIntl()
  return (
    <div className='card h-100 shadow border border-2 border-gray-300 border-hover-primary overlay-wrapper card-rounded' >
      <div className='card-header flex-nowrap border-0 pt-9'>
        <div className='card-title m-0'>
          <div className='symbol symbol-100px w-100px bg-light me-5'>
            <img src={toAbsoluteUrl(image)} alt='Card7' className='p-1 h-auto w-100' />
          </div>
          <div>
            <span className={`fs-3 fw-bolder text-dark me-auto`}>
              {title}
            </span>
            <div className='fw-bold text-gray-600 me-auto fs-5 m-0'>{category}</div>
          </div>
        </div>
      </div>

      <div className='card-body d-flex flex-column px-9 pt-6 pb-6 h-60px overflow'>
        {description ? parse(description) : ""}
      </div>
      <div className='card-footer'>
        <a href={link} className='btn btn-sm btn-primary'>{intl.formatMessage({ id: "BTN.VIEW_NOMINEE" })}</a>
      </div>
    </div>
  )
}

export { Card5 }

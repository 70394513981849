
import React from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { EventList } from './EventList'
import { useIntl } from 'react-intl'

type Props = {
    company_details: any
    page: any
    limit: any
    setPage: any
    totalPage: any
    eventList: any
}

const CompanyDetails: React.FC<Props> = ({ company_details, page, limit, setPage, totalPage, eventList }) => {
    const intl = useIntl()
    return (
        <>
            <div className={`card`}>
                {/* begin::Body */}
                <div className='card-body p-0'>
                    {/* begin::Header */}
                    <div className='image-input-wrapper w-100 min-h-400px bg-light-secondary img-cover' style={{
                        background: `url(${company_details?.company_banner ? company_details?.company_banner : toAbsoluteUrl('../media/stock/1600x800/img-1.jpg')})`
                    }}></div>
                    <div
                        className='shadow-xs card-rounded mx-15 mb-15 px-6 py-1 position-relative z-index-1 bg-body'
                        style={{ marginTop: '-20px' }}
                    >
                        <div className={`card row`}>
                            <div className='card-header card-title m-0 pt-10'>
                                <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: "TEXT.COMPANY_DETAILS" })}</h3>
                            </div>
                            <div className='card-body pb-0'>
                                <div className='row mb-7'>
                                    <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: "TEXT.COMPANY_NAME" })}</label>

                                    <div className='col-lg-8 fv-row'>
                                        <h3 className='fw-bold'>{
                                            company_details?.company_name
                                        }</h3>
                                    </div>
                                </div>

                                <div className='row mb-7'>
                                    <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: "TEXT.TL_NO" })}</label>

                                    <div className='col-lg-8 fv-row'>
                                        <span className='fw-bold fs-6'>{
                                            company_details?.company_tl_number
                                        }</span>
                                    </div>
                                </div>

                                <div className='row mb-7'>
                                    <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: "TEXT.COMPANY_BIO" })}</label>

                                    <div className='col-lg-8 fv-row'>
                                        <span className='fw-bold fs-6'>{
                                            company_details?.company_bio
                                        }</span>
                                    </div>
                                </div>

                                <div className='row mb-7'>
                                    <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: "TEXT.REPREN_NAME" })}</label>

                                    <div className='col-lg-8 fv-row'>
                                        <span className='fw-bold fs-6'>{
                                            company_details?.representative_name
                                        }</span>
                                    </div>
                                </div>
                                <div className='row mb-7'>
                                    <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: "TEXT.REPREN_TLNO" })}</label>

                                    <div className='col-lg-8 fv-row'>
                                        <span className='fw-bold fs-6'>{
                                            company_details?.representative_tl_number
                                        }</span>
                                    </div>
                                </div>
                                <div className='row mb-7'>
                                    <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: "TEXT.COMPANY_ADDRESS" })}</label>

                                    <div className='col-lg-8 fv-row'>
                                        <span className='fw-bold fs-6'>{
                                            company_details?.address
                                        }</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <EventList list={eventList} page={page} setPage={setPage} totalPage={totalPage} limit={limit} />
                </div>
            </div></>)
}
export { CompanyDetails }

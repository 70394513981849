/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Card4 } from '../../../_metronic/partials/content/cards/Card4'
import { useSelector } from 'react-redux'
import { eventDetails } from '../../../store/Event/EventSlice'
import { useIntl } from 'react-intl'

const SponserPage: React.FC = () => {
  const event_details = useSelector(eventDetails)
  const intl = useIntl()

  return (
    <>
      {event_details?.event?.sponser_list?.gold?.length == 0 &&
        event_details?.event?.sponser_list?.silver?.length == 0 &&
        event_details?.event?.sponser_list?.bronze?.length == 0 && (
          <div className='card row mb-5 p-5'> {intl.formatMessage({ id: "RECEPTION.SPONSER.NOT.FOUND" })}</div>
        )}
      {event_details?.event?.sponser_list?.gold?.length > 0 && (
        <>
          <h5 className='mb-5 sponsor-area-gold'>{intl.formatMessage({ id: "RECEPTION.GOLD" })}</h5>
          <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
            {event_details?.event?.sponser_list?.gold.map((g: any, i: number) => (
              <div className='col-4 col-sm-12 col-xl-4' key={i}>
                <Card4
                  icon={g?.image ? g?.image : '/media/avatars/blank.png'}
                  title={g?.title}
                  description={g?.website}
                  class_name='symbol symbol-150px mb-6'
                />
              </div>
            ))}
          </div>
        </>
      )}

      {event_details?.event?.sponser_list?.silver?.length > 0 && (
        <>
          <h5 className='mb-5 sponsor-area-silver'>{intl.formatMessage({ id: "RECEPTION.SILVER" })}</h5>
          <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
            {event_details?.event?.sponser_list?.silver.map((s: any, i: number) => (
              <div className='col-3 col-sm-12 col-xl-3' key={i}>
                <Card4
                  icon={s?.image ? s?.image : '/media/avatars/blank.png'}
                  title={s?.title}
                  description={s?.website}
                  class_name='symbol symbol-150px mb-6'
                />
              </div>
            ))}
          </div>
        </>
      )}

      {event_details?.event?.sponser_list?.bronze?.length > 0 && (
        <>
          <h5 className='mb-5 mt-10 sponsor-area-bronze'>{intl.formatMessage({ id: "TEXT.BRONZE" })}</h5>
          <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
            {event_details?.event?.sponser_list?.bronze.map((b: any, i: number) => (
              <div className='col-2 col-sm-12 col-xl-2' key={i}>
                <Card4
                  icon={b?.image ? b?.image : '/media/avatars/blank.png'}
                  title={b?.title}
                  description={b?.website}
                  class_name='symbol symbol-150px mb-6'
                />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  )
}

export { SponserPage }

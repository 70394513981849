/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { PollState } from '../../../../app/modules/Socket/PollProvider'
import Poll from '../../Poll/Poll'
import { CreatePoll } from '../../modals/poll/CreatePoll'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

type Props = {
  className: string
  title?: string
}

const TablesWidget1: React.FC<Props> = ({ className, title }) => {
  const user_type = localStorage.getItem('user_type')
  const { poll, submitPolls, dispatchPoll } = PollState()
  const [modal, setModal] = useState(false)
  const [activeTab, setActiveTab] = useState(1)

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      {/* <div className='card-header border-1 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-active-light-primary me-n5'
            id='kt_poll_close'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </button>
        </div>
      </div> */}
      {/* end::Header */}
      <div className='card-header d-flex'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li className='nav-item'>
            <a
              className={`nav-link text-active-primary me-6 active`}
              data-bs-toggle='tab'
              href='#kt_poll_1'
              onClick={() => { setActiveTab(1); dispatchPoll({ type: "POLL_TAB", payload: "poll_list" }) }}
            >
              Poll List
            </a>
          </li>
          <li className='nav-item'>
            <a
              className={`nav-link text-active-primary me-6 `}
              data-bs-toggle='tab'
              href='#kt_poll_2'
              onClick={() => { setActiveTab(2); dispatchPoll({ type: "POLL_TAB", payload: "show_result" }) }}
            >
              Show Result
            </a>
          </li>
        </ul>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-active-light-primary me-n5'
            id='kt_poll_close'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </button>
        </div>
      </div>
      {/* begin::Body */}
      {activeTab === 1 &&
        <div
          style={{ minHeight: '600px' }}
          className={clsx(
            'card-body scroll-y me-n5 pe-10 h-lg-auto py-3',
            activeTab != 1 && 'd-none'
          )}
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies={
            '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
          }
          data-kt-scroll-wrappers={'#kt_drawer_chat_messenger_body'}
          data-kt-scroll-offset={'0px'}
          id='kt_poll_1'
        >
          {/* begins::Table container */}
          <div className='table-responsive overflow-hidden'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-5'>
              {/* begin::Table body */}
              <tbody>
                {poll.pollList.length > 0 ? (
                  poll.pollList.map((poll: any, i: number) => {
                    if (user_type === 'owner' || poll.is_share) {
                      return (
                        <Poll poll={poll} submitPolls={submitPolls} key={i} type="share_poll" />
                      )
                    }
                  }
                  )
                ) : (
                  <>
                    <div className='d-flex justify-content-center flex-column flex-center h-500px'>
                      <div className='no-polls-logo'>
                        <i className='bi bi-bar-chart-fill poll-logo'></i>
                      </div>
                      <h4 className='mt-2'>No polls yet</h4>
                      {user_type === 'owner' && (
                        <h5 className='text-center text-muted'>
                          Add a poll to your event that your attendees can respond to
                        </h5>
                      )}
                    </div>
                  </>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      }

      {activeTab === 2 &&

        <div
          style={{ minHeight: '600px' }}
          className={clsx(
            'card-body scroll-y me-n5 pe-10 h-lg-auto py-3',
            activeTab != 2 && 'd-none'
          )}
          data-kt-element='messages'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies={
            '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
          }
          data-kt-scroll-wrappers={'#kt_drawer_chat_messenger_body'}
          data-kt-scroll-offset={'0px'}
          id='kt_poll_2'
        >
          {/* begins::Table container */}
          <div className='table-responsive overflow-hidden'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-5'>
              {/* begin::Table body */}
              <tbody>
                {poll.pollList.length > 0 ? (
                  poll.pollList.map((poll: any, i: number) => {
                    if (poll.is_share_result || user_type === "owner") {
                      return (
                        <Poll poll={poll} submitPolls={submitPolls} key={i} type="share_result" />
                      )
                    }
                  })
                ) : (
                  <>
                    <div className='d-flex justify-content-center flex-column flex-center h-500px'>
                      <div className='no-polls-logo'>
                        <i className='bi bi-bar-chart-fill poll-logo'></i>
                      </div>
                      <h4 className='mt-2'>No polls yet</h4>
                      {user_type === 'owner' && (
                        <h5 className='text-center text-muted'>
                          Add a poll to your event that your attendees can respond to
                        </h5>
                      )}
                    </div>
                  </>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      }

      {(user_type === 'owner' && activeTab === 1) && (
        <div className='card-footer'>
          <div className='text-end'>
            <button className='btn btn-primary' onClick={() => setModal(true)}>
              Create poll
            </button>
          </div>
        </div>
      )}
      <CreatePoll modal={modal} setModal={setModal} />
    </div>
  )
}

export { TablesWidget1 }

import {useSelector} from 'react-redux'

const Loading = ({children}: any) => {
  const isLoading = useSelector((state: any) => state.loader.isLoading)
  return (
    <>
      {isLoading && <div id='preloader'></div>}
      {children}
    </>
  )
}

export default Loading

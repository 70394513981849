/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { eventDetails, event_detail } from '../../../store/Event/EventSlice'
import { Card4 } from '../../../_metronic/partials/content/cards/Card4'
import clsx from 'clsx'
import { ProfileHeader2 } from './ProfileHeader2'
import { MixedWidget1 } from '../../../_metronic/partials/widgets/mixed/MixedWidget1'
import { ProfileHeader } from './ProfileHeader'
import { useParams } from 'react-router-dom'
import { loaderChange } from '../../../store/Loader/LoaderSlice'
import { useIntl } from 'react-intl'
const parse = require('html-react-parser')


const ReceptionPage: React.FC = () => {
  const id = localStorage.getItem("event_id");
  const dispatch = useDispatch();
  const event_details = useSelector(eventDetails)
  const intl = useIntl()


  useEffect(() => {
    dispatch(loaderChange(true))
    dispatch(event_detail({ event_id: id })).then((res: any) => {
      dispatch(loaderChange(false))
    })
      .catch((err: any) => {
        dispatch(loaderChange(false))
      })
  }, [])

  let gridTemplatecolumns = ''
  for (let index = 0; index < event_details?.event?.sponser_list?.silver?.length; index++) {
    gridTemplatecolumns += ' 325px'
  }

  let gridbronzeTemplatecolumns = ''
  for (let index = 0; index < event_details?.event?.sponser_list?.bronze?.length; index++) {
    gridbronzeTemplatecolumns += ' 275px'
  }

  return (
    <>
      {localStorage.getItem('event_type') == "company_event" ? <>
        <div className='row g-5 g-xl-8'>
          {/* begin::Col */}
          <div className='col-xl-12 col-12'>
            <MixedWidget1 className='card-xl-stretch mb-xl-8'
              gridbronzeTemplatecolumns={gridbronzeTemplatecolumns}
              gridTemplatecolumns={gridTemplatecolumns}
            />
          </div>
          {/* end::Col */}
        </div>
      </> :
        <>
          <ProfileHeader />
          <div className={`card row pt-5 pb-5`}>
            <ProfileHeader2
              image={event_details?.event?.thumbnail}
              title={event_details?.event?.title}
              hosted_by={event_details?.event?.hosted_by}
              desc={
                event_details?.event?.welcome_message
                  ? parse(event_details?.event?.welcome_message)
                  : ''
              }
            />
          </div>
          <div className={`card row mt-10 pb-5`}>
            <div className='card-header card-title m-0 pt-10'>
              <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: 'RECEPTION.SPONSER' })}</h3>
            </div>
            {event_details?.event?.sponser_list?.gold?.length == 0 &&
              event_details?.event?.sponser_list?.silver?.length == 0 &&
              event_details?.event?.sponser_list?.bronze?.length == 0 && (
                <div className='pt-10 pb-0 ps-9'>{intl.formatMessage({ id: 'RECEPTION.SPONSER.NOT.FOUND' })}</div>
              )}
            {/* begin::Body */}
            <div className='card-body pb-0'>
              {event_details?.event?.sponser_list?.gold?.length > 0 && (
                <>
                  <h5 className='mb-10 sponsor-area-gold'>{intl.formatMessage({ id: 'RECEPTION.GOLD' })}</h5>
                  <div className={clsx('row g-6 mb-6 pb-6 w-auto h-350px sponser_ui', (event_details?.event?.sponser_list?.gold?.length > 2 || event_details?.event?.sponser_list?.gold?.length == 1) && "d-grid")}>
                    {event_details?.event?.sponser_list?.gold.map((g: any, i: number) => (
                      <div
                        className={clsx('mx-5 card shadow col-sm-12 col-md-4 col-xl-4 min-w-375px')}
                        key={i}
                      >
                        <Card4
                          icon={g?.image ? g?.image : '/media/avatars/blank.png'}
                          title={g?.title}
                          description={g?.website}
                          class_name='symbol symbol-200px mb-6 w-100 mt-6'
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}

              {event_details?.event?.sponser_list?.silver?.length > 0 && (
                <>
                  <h5 className='mb-5 sponsor-area-silver'>{intl.formatMessage({ id: 'RECEPTION.SILVER' })}</h5>
                  <div
                    className={clsx('row g-xl-9 mb-6 h-300px sponser_ui pb-6', (event_details?.event?.sponser_list?.silver?.length > 2 || event_details?.event?.sponser_list?.silver?.length == 1) && "d-grid")}
                    style={{
                      gridTemplateColumns: gridTemplatecolumns,
                    }}
                  >
                    {event_details?.event?.sponser_list?.silver?.map((s: any, i: number) => (
                      <div className='mx-5 card shadow col-sm-12 mb-5' key={i}>
                        <Card4
                          icon={s?.image ? s?.image : '/media/avatars/blank.png'}
                          title={s?.title}
                          description={s?.website}
                          class_name='symbol symbol-auto mb-6 mt-6'
                          image_class='h-150px w-100'
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}

              {event_details?.event?.sponser_list?.bronze?.length > 0 && (
                <>
                  <h5 className='mb-5 mt-10 sponsor-area-bronze'>{intl.formatMessage({ id: 'RECEPTION.BRONZE' })}</h5>
                  <div
                    className={clsx('row g-6 mb-6 mb-xl-9 sponser_ui pb-6 w-auto h-275px', (event_details?.event?.sponser_list?.bronze?.length > 2 || event_details?.event?.sponser_list?.bronze?.length == 1) && "d-grid")}
                    style={{ gridTemplateColumns: gridbronzeTemplatecolumns }}
                  >
                    {event_details?.event?.sponser_list?.bronze.map((b: any, i: number) => (
                      <div className='mx-5 card shadow col-sm-12 ' key={i}>
                        <Card4
                          icon={b?.image ? b?.image : '/media/avatars/blank.png'}
                          title={b?.title}
                          description={b?.website}
                          class_name='symbol symbol-80px mb-6 mt-6'
                          image_class='h-100px w-100'
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            {/* end::Body */}
          </div>
        </>
      }
    </>
  )
}

export { ReceptionPage }

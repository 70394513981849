import { Routes, Route, Outlet, Navigate, useParams } from 'react-router-dom'
import { AwardList } from './components/AwardList'
import { Nominees } from './components/Nominees'

const AwardPage = () => (
    <Routes>
        <Route
            element={
                <>
                    <Outlet />
                </>
            }
        >
            <Route
                path='/'
                element={
                    <>
                        <AwardList />
                    </>
                }
            />
            <Route
                path='/nominees'
                element={
                    <>
                        <Nominees />
                    </>
                }
            />
            
        </Route>
    </Routes>
)

export default AwardPage

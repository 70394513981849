export const {REACT_APP_API_URL: API_BASE} = process.env

export const API_EVENT = `${API_BASE}/event/`

export const API_LIVESTAGE = `${API_BASE}/livestage/`
export const API_LIVESTAGE_DETAILS = `${API_BASE}/livestage/detail/`

export const API_SPONSER = `${API_BASE}/sponsor/`
export const API_SPONSER_DETAILS = `${API_BASE}/sponsor/detail/`

export const API_SESSION_LIST = `${API_BASE}/session/`
export const API_SESSION_DETAILS = `${API_BASE}/session/detail/`

export const API_AUTH = `${API_BASE}/event/auth_details`
export const API_BOOTH_LIST = `${API_BASE}/booth/`
export const API_BOOTH_DETAILS = `${API_BASE}/booth/detail/`

export const API_SUBSCRIBE_NEWSLATTER = `${API_BASE}/users/subscribeForNewslatter`
export const API_COMPLAINT_ADD = `${API_BASE}/users/sendComplaints/`

// award
export const API_LIST_OF_AWARDS = `${API_BASE}/award/getListOfAwards`
export const API_LIST_OF_NOMINEES = `${API_BASE}/award/getListOfNominees`
export const API_VOTE_NOW = `${API_BASE}/award/voteNow`

//company

export const API_COMPANY_DETAILS = `${API_BASE}/company/getCompanyDetails`
export const API_COMPANY_EVENT_LISTS = `${API_BASE}/company/getCompanyEvents`
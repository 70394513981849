import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loaderChange } from '../../../../store/Loader/LoaderSlice'
import { Footer } from '../../../../_metronic/layout/components/Footer'
import { MixedWidget1 } from '../../../../_metronic/partials/widgets/mixed/MixedWidget1'
import { eventDetails, event_detail } from '../../../../store/Event/EventSlice'
import { useParams } from 'react-router-dom'
import { AwardList } from '../../awards/components/AwardList'

const CompanyEventDetail: React.FC = () => {
  const event_id = useParams().event_id;
  const dispatch = useDispatch();
  const event_details = useSelector(eventDetails)

  useEffect(() => {
    dispatch(loaderChange(true))
    dispatch(event_detail({ event_id: event_id })).then((res: any) => {
      dispatch(loaderChange(false))
    })
      .catch((err: any) => {
        dispatch(loaderChange(false))
      })
  }, [])

  let gridTemplatecolumns = ''
  for (let index = 0; index < event_details?.event?.sponser_list?.silver?.length; index++) {
    gridTemplatecolumns += ' 325px'
  }

  let gridbronzeTemplatecolumns = ''
  for (let index = 0; index < event_details?.event?.sponser_list?.bronze?.length; index++) {
    gridbronzeTemplatecolumns += ' 275px'
  }

  return (
    <>
      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-12 col-12'>
          <MixedWidget1 className='card-xl-stretch mb-xl-8'
            gridbronzeTemplatecolumns={gridbronzeTemplatecolumns}
            gridTemplatecolumns={gridTemplatecolumns}
          />

        </div>
        {/* end::Col */}
        <div className='mx-2 mt-5'><AwardList /></div>
      </div>
    </>
  )
}

export { CompanyEventDetail }

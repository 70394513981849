import React from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { ProfileHeader2 } from '../../../../app/modules/event/ProfileHeader2'
import { useSelector } from 'react-redux'
import { eventDetails } from '../../../../store/Auth/AuthSlice'
import parse from "html-react-parser"
import { Card4 } from '../../content/cards/Card4'
import clsx from 'clsx'
import { EventDetails } from '../company-event/EventDetails'
import { Sponsers } from '../company-event/Sponsers'
import { AwardList } from '../../../../app/modules/awards/components/AwardList'
import { useIntl } from 'react-intl'

type Props = {
  className: string
  gridbronzeTemplatecolumns: any
  gridTemplatecolumns: any
}

const MixedWidget1: React.FC<Props> = ({ className, gridbronzeTemplatecolumns, gridTemplatecolumns }) => {
  const event_details = useSelector(eventDetails)
  const intl = useIntl()
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div className='image-input-wrapper w-100 min-h-400px img-cover shadow' style={{
          background: `url(${event_details?.event?.banner ? event_details?.event?.banner
            : toAbsoluteUrl('../../media/svg/files/blank-image.svg')})`
        }}></div>
        {/* end::Header */}
        {/* begin::Items */}
        <div
          className='shadow-xs card-rounded mx-15 mb-15 px-6 py-1 position-relative z-index-1 bg-body'
          style={{ marginTop: '-20px' }}
        >
          <div className={`card row pt-2 pb-2`}>
            <ProfileHeader2
              image={event_details?.event?.thumbnail}
              title={event_details?.event?.title}
              hosted_by={event_details?.event?.hosted_by}
              desc={
                event_details?.event?.welcome_message
                  ? parse(event_details?.event?.welcome_message)
                  : ''
              }
            />
          </div>
        </div>
        {/* end::Items */}

        <EventDetails event_details={event_details} />
        {/* begin::Items */}
        <Sponsers gridbronzeTemplatecolumns={gridbronzeTemplatecolumns} gridTemplatecolumns={gridTemplatecolumns} event_details={event_details} />
      </div>
      {/* end::Body */}
    </div>
  )
}

export { MixedWidget1 }

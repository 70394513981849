/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { useParams } from 'react-router-dom'

type Props = {
  icon: string
  title: string
  description: string
  class_name: any
  image_class?: string
}

const Card4: FC<Props> = ({ icon, title, description, class_name, image_class }) => {
  const { company_id } = useParams()
  let classname = image_class ? image_class : 'h-175px w-100'
  return (
    <div className='card-body d-flex justify-content-center text-center flex-column p-5'>
      <a href={!company_id ? description : "#"} target='_blank' className='text-gray-800 d-flex flex-column'>
        <div className={class_name}>
          <img src={toAbsoluteUrl(icon)} alt='' className={classname} />
        </div>
        <div className='fs-5 fw-bolder mb-2'>{title}</div>
      </a>
    </div>
  )
}

export { Card4 }

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { eventDetails } from '../../../store/Event/EventSlice'
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link, useParams } from 'react-router-dom'
import { boothDetails, boothDetailsAction } from '../../../store/Booth/BoothSlice'
import { loaderChange } from '../../../store/Loader/LoaderSlice'
import { ProfileHeader2 } from '../event/ProfileHeader2'

const BoothDetails = () => {
  const boothDetailsData = useSelector(boothDetails)
  const dispatch = useDispatch()
  const { id } = useParams();

  useEffect(() => {
    dispatch(loaderChange(true))
    dispatch(boothDetailsAction(id))
      .then((res: any) => {
        dispatch(loaderChange(false))
      })
      .catch((err: any) => {
        dispatch(loaderChange(false))
      })
  }, [])

  return (
    <>
      {' '}
      <div className='card row mb-10'>
        <div className='card-body col-12 pt-9 pb-0'>
          <img
            src={
              boothDetailsData?.banner
                ? boothDetailsData?.banner
                : toAbsoluteUrl('../../media/svg/files/blank-image.svg')
            }
            className='mh-400px bgi-no-repeat bgi-position-center bgi-size-contain w-100'
          />
          <div className='p-5'></div>
        </div>
        {/* begin::Body */}
        <div className='card-body p-0'>
          <ProfileHeader2
            image={boothDetailsData?.image}
            title={boothDetailsData?.name}
            email={boothDetailsData?.email}
            desc={boothDetailsData?.headline}
            website_link={boothDetailsData?.website_link}
            twitter_link={boothDetailsData?.twitter_link}
            facebook_link={boothDetailsData?.facebook_link}
            linkedin_link={boothDetailsData?.linkedin_link}
            instagram_link={boothDetailsData?.instagram_link}
          />
          <div className=' card d-flex justify-content-between align-items-start flex-wrap'>
            {boothDetailsData?.additional_information && (
              <div className='mb-5 card-header pt-5'>
                <div className='card-tile text-gray-800 fs-4 fw-bolder'>Additional Info</div>
                <div className='mt-4 text-muted'>
                  <p dangerouslySetInnerHTML={{ __html: boothDetailsData?.additional_information }} />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* end::Body */}
      </div>
    </>
  )
}

export default BoothDetails

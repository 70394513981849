/* eslint-disable jsx-a11y/anchor-is-valid */

import { PollState } from "../../../../app/modules/Socket/PollProvider"

const PollDropDown = (props: any) => {
  const { sharePoll, deletePoll } = PollState()
  return (
    <div
      className={`${props.isMenuOpen ? 'd-block position-absolute r-0 poll_option' : 'd-none'
        } menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px`}
    >

      <div className='menu-item px-3' onClick={() => { sharePoll(props.poll.id, props.type); props.setIsMenuOpen(false) }}>
        <p className='menu-link px-3 m-0 pb-0'>
          Share Poll
        </p>
      </div>

      <div className='menu-item px-3' onClick={() => { deletePoll(props.poll.id); props.setIsMenuOpen(false) }}>
        <p className='menu-link px-3 m-0 pt-0'>
          Delete Poll
        </p>
      </div>

    </div>
  )
}

export { PollDropDown }

import { Routes, Route } from 'react-router-dom'
import { EventsPage } from './Events/EventsPage'
import { CompanyEventDetail } from './Events/CompanyEventDetail'
import { Nominees } from '../awards/components/Nominees'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { useIntl } from 'react-intl'

const CompanyEventPage = () => {
  const intl = useIntl()
  const companyBreadCrumbs: Array<PageLink> = [
    {
      title: `${intl.formatMessage({ id: "TEXT.HOME" })}`,
      path: `/company-event/${localStorage.getItem("bananryCompanyId")}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  
  return (
    <Routes>
      <Route>
        <Route
          path='/:company_id'
          element={
            <>
              <PageTitle>{intl.formatMessage({ id: "TEXT.HOME" })}</PageTitle>
              <EventsPage />
            </>
          }
        />
        <Route
          path='/:company_id/:event_id'
          element={
            <>
              <PageTitle breadcrumbs={companyBreadCrumbs}>{intl.formatMessage({ id: "TEXT.EVENT_DETAILS" })}</PageTitle>
              <CompanyEventDetail />
            </>
          }
        />
        <Route
          path='/:company_id/:event_id/:award_id/nominees'
          element={
            <>
              <PageTitle breadcrumbs={companyBreadCrumbs}>{intl.formatMessage({ id: "TEXT.AWARDNOMINEE" })}</PageTitle>
              <Nominees />
            </>
          }
        />
      </Route>
    </Routes>
  )
}
export default CompanyEventPage

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { add_complaints } from '../../../../../../store/Auth/AuthSlice'
import { toastr } from 'react-redux-toastr'
import { useIntl } from 'react-intl'

const ComplaintForm: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [feedback, setFeedback] = useState("")
  const [disabled, setDisabled] = useState(true)
  const dispatch = useDispatch()
  const intl = useIntl()

  useEffect(() => {
    if (feedback != "") {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [feedback])

  const addComplaints = (e: any) => {
    setLoading(true)
    e.preventDefault();
    dispatch(
      add_complaints({
        user_name: localStorage.getItem("user_name"),
        host_id: localStorage.getItem("host_id"),
        event_id: localStorage.getItem("event_id"),
        event_name: localStorage.getItem("event_name"),
        feedback: feedback
      })
    )
      .then((res: any) => {
        toastr.success(intl.formatMessage({ id: "TEXT.SUCCESS" }), intl.formatMessage({ id: "MSG.COMPLAIN_SEND_SUCC" }))
        setFeedback("")
        setLoading(false)
      })
      .catch((err: any) => {
        setLoading(false)
      })
  }

  return (
    <div className='card mb-xl-10'>
      <div
        className='card-header border-0'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: "MENU.RAISE_COMPLAIN" })}</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={addComplaints}>
          <div className='card-body border-top p-9'>
            <div className='row'>
              <label className='col-lg-3 col-form-label required fw-bold fs-6'>{intl.formatMessage({ id: "TEXT.DESCRIBE_ISSUE" })}</label>
              <div className='col-lg-9'>
                <textarea
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder={intl.formatMessage({ id: "TEXT.DESCRIBE_ISSUE" })}
                  rows={5}
                  value={feedback}
                  onChange={(e: any) => { setFeedback(e.target.value); }}
                ></textarea>

              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={disabled}>
              {!loading && intl.formatMessage({ id: "TEXT.SEND" })}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  {intl.formatMessage({ id: "TEXT.PLEASE_WAIT" })}{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>

    </div>
  )
}

export { ComplaintForm }

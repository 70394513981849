import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const Error401: FC = () => {
  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
        <h1 className='fw-bold mb-5 fs-2hx' >
          401 Unauthorized
        </h1>
        {/* begin::Text */}
        <div className='fw-bold fs-2 mb-5'>JWT token is invalid.</div>
        {/* end::Text */}
        {/* begin::Illustration */}
        <img
          src={toAbsoluteUrl('/media/auth/error.svg')}
          alt=''
          className='mw-100 mb-10 h-lg-300px'
        />
        {/* end::Illustration */}

        {/* begin::Link */}
        <Link to='/' className='btn btn-sm btn-primary'>
          Return Home
        </Link>
        {/* end::Link */}
      </div>
    </div>
  )
}

export { Error401 }

/* eslint-disable jsx-a11y/anchor-is-valid */
import {useSelector} from 'react-redux'
import {formatRelativeTime} from '../../../../utils'
import {ChatState} from '../../../../app/modules/Socket/ChatProvider'

const HeaderNotificationsMenu = ({setLimit, limit}: any) => {
  const {
    chatList: {notificationList},
  } = ChatState()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div className='d-flex flex-column bgi-no-repeat rounded-top bg bg-primary'>
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>Notifications</h3>
      </div>

      <div className='tab-content'>
        <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-325px'>
            {notificationList?.notifications?.length > 0 ? (
              notificationList?.notifications.map((n: any, index: any) => (
                <div key={index}>
                  <a
                    href={n.url}
                    key={`alert${index}`}
                    className='d-flex flex-stack py-4 bg bg-hover-secondary'
                  >
                    <div className='px-8 d-flex align-items-center'>
                      <span>
                        <div className='mb-0 me-2'>
                          <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                            {n.title}
                          </span>
                          <div className='text-gray-600 fs-6 fw-semibold'>{n.message}</div>
                        </div>
                      </span>
                    </div>

                    <span className='badge badge-secondary fs-8 me-8'>
                      {formatRelativeTime(n.created_at)}
                    </span>
                  </a>
                  <div className='separator separator-solid-black'></div>
                </div>
              ))
            ) : (
              <div className='p-10'>Notifications not found.</div>
            )}
          </div>

          {/* <div className='py-3 text-center border-top'>
            <Link
              to='#'
              onClick={() => setLimit((prev: any) => Number(prev) + 10)}
              className='btn btn-active-color-primary btn-color-primary'
              aria-disabled={limit > 30 ? false : true}
            >
              View More
              <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}

import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { awards, list_of_awards } from '../../../store/Award/AwardSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { loaderChange } from '../../../store/Loader/LoaderSlice'
import parse from 'html-react-parser'
import { useIntl } from 'react-intl'

const AwardHeader: React.FC = (props: any) => {
  const { award_id, company_id } = useParams()
  const id = localStorage.getItem("event_id");
  const awardList = useSelector(awards)
  const [award, setAward]: any = useState();
  const dispatch = useDispatch()
  const intl = useIntl()

  useEffect(() => {
    dispatch(loaderChange(true))
    dispatch(list_of_awards({ event_id: id })).then((res: any) => {
      dispatch(loaderChange(false))
    })
      .catch((err: any) => {
        dispatch(loaderChange(false))
      })
  }, [])

  useEffect(() => {
    setAward(awardList.filter((al: any) => al.id == award_id)[0])
  }, [awardList])

  return (
    <div className='card row mb-10'>
      <div className='card-header d-flex flex-wrap flex-stack'>
        <h3 className='card-title fw-bolder my-2'>
          {intl.formatMessage({ id: "TEXT.AWARD_DETAILS" })}
        </h3>
        <div className='d-flex align-items-center my-2'>
          <a href={company_id ? `/company-event/${company_id}/${id}` : `/event/${id}/awards`} className='btn btn-secondary btn-sm' data-bs-toggle='tooltip'>
            {intl.formatMessage({ id: "BTN.BACK_AWARDS" })}
          </a>
        </div>
      </div>
      <div className='card-body d-flex flex-wrap flex-sm-nowrap'>
        <div className='me-7'>
          <div className='image-input-wrapper w-175px h-175px bg-light-secondary' style={{
            background: `url(${award?.image
              ? award?.image
              : toAbsoluteUrl('../../media/svg/files/blank-image.svg')})`
          }}></div>
        </div>

        <div className='flex-grow-1'>
          <div className='d-flex justify-content-between align-items-center flex-wrap'>
            <div className='d-flex flex-column'>
              <div className='d-flex align-items-center mb-3'>
                <label className='text-gray-800 fs-4 fw-bolder me-1 mt-4'>{award?.title}</label>
              </div>

              <div className='fs-6 text-gray-500 mb-3'>
                <strong className='fw-bold'>{award?.category}</strong>
              </div>
              <div className='text-muted '>{award?.description ? parse(award?.description) : ""}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { AwardHeader }

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector } from 'react-redux';
import { Card2 } from '../../../../_metronic/partials/content/cards/Card2'
import { SessionList } from '../../../../store/Session/SessionSlice';
import {useIntl} from 'react-intl'


export function Projects() {
  const sessions = useSelector(SessionList)
  const intl = useIntl()

  return (
    <>
      <div className='card-header d-flex flex-wrap flex-stack'>
        <h3 className='card-title fw-bolder'>{intl.formatMessage({id: 'SESSION.SESSIONS'})}</h3>
      </div>

      <div className='card-body row'>
        {sessions && sessions?.length > 0 ? (
          <>
            {sessions?.map((s: any, i: number) => {
              return (
                <div className='col-sm-6 col-md-4 col-xl-4 mb-10' key={i}>
                  <Card2
                    icon={s?.image}
                    title={s?.title}
                    description={s?.description}
                    totalView={1}
                    link={`/event/${localStorage.getItem('event_id')}/session/${s?.session_id}`}
                    isLive={s?.isLive}
                    startdatetime={s?.startdatetime}
                    enddatettime={s?.enddatettime}
                  />
                </div>
              )
            })}
          </>
        ) : (
          <div className='pl-3'>{intl.formatMessage({id: 'SESSION.NOT.FOUND'})}</div>
        )}
      </div>
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import clsx from 'clsx'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap'
import { useIntl } from 'react-intl'

type Props = {
  image: string
  modalShow: boolean
  setModalShow: any
}

const ViewModal: FC<Props> = ({ image = "", modalShow = false, setModalShow }) => {
  const intl = useIntl()
  return (
    <Modal show={modalShow} centered className='fade' size='lg' onHide={() => setModalShow(false)}>
      <ModalHeader>
        <div className='w-100'>
          <div className='d-flex justify-content-between'>
            <h2 className='m-auto'>{intl.formatMessage({ id: "TEXT.VIEW" })}</h2>
            <h3 style={{ cursor: 'pointer' }} onClick={() => setModalShow(false)}>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </h3>
          </div>
        </div>
      </ModalHeader>
      <ModalBody className='p-8 justify-content-center align-items-center text-center'>
        {image.includes(".mp4") ?
          <video src={toAbsoluteUrl(image)} className='p-1 w-auto h-550px' controls controlsList="nodownload" onContextMenu={(e) => e.preventDefault()}></video>
          : <img src={toAbsoluteUrl(image)} alt='Card7' className='p-1 w-auto mh-600px' />}
      </ModalBody>

    </Modal>
  )
}

export { ViewModal }

import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../helpers'
import {Item1} from '../../content/activity/Item1'
import {Item2} from '../../content/activity/Item2'
import {Item3} from '../../content/activity/Item3'
import {Item4} from '../../content/activity/Item4'
import {Item5} from '../../content/activity/Item5'
import {Item6} from '../../content/activity/Item6'
import {Item7} from '../../content/activity/Item7'
import {Item8} from '../../content/activity/Item8'
import {TablesWidget1} from '../../widgets/tables/TablesWidget1'

const PollDrawer: FC = () => (
  <div
    id='kt_poll'
    data-kt-drawer='true'
    data-kt-drawer-name='announcement'
    data-kt-drawer-activate='true'
    data-kt-drawer-overlay='true'
    data-kt-drawer-width="{default:'300px', 'lg': '400px'}"
    data-kt-drawer-direction='end'
    data-kt-drawer-toggle='#kt_poll_toggle'
    data-kt-drawer-close='#kt_poll_close'
  >
    <TablesWidget1 className={'w-400px'} title='Poll List' />
  </div>
)

export {PollDrawer}

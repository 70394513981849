/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Item1 } from '../../../_metronic/partials/content/activity/Item1'
import Moment from 'react-moment'
import { useDispatch, useSelector } from 'react-redux'
import { eventDetails, event_detail } from '../../../store/Event/EventSlice'
import { ProfileHeader2 } from './ProfileHeader2'
import { loaderChange } from '../../../store/Loader/LoaderSlice'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../_metronic/helpers/components/KTSVG'
const parse = require('html-react-parser')

const ScedulePage: React.FC = () => {
  const id = localStorage.getItem("event_id");
  const dispatch = useDispatch();
  const event_details = useSelector(eventDetails)
  const intl = useIntl()


  useEffect(() => {
    dispatch(loaderChange(true))
    dispatch(event_detail({ event_id: id })).then((res: any) => {
      dispatch(loaderChange(false))
    })
      .catch((err: any) => {
        dispatch(loaderChange(false))
      })
  }, [])

  return (<>  <div className={`card row pt-5 pb-5`}>
    <ProfileHeader2
      image={event_details?.event?.thumbnail}
      title={event_details?.event?.title}
      hosted_by={event_details?.event?.hosted_by}
      desc={
        event_details?.event?.welcome_message
          ? parse(event_details?.event?.welcome_message)
          : ''
      }
    />
  </div>
    <div className={`card  mt-10 row`}>
      {/* begin::Body */}
      <div className='card-body pt-10 overflow-scroll'>
        {/* begin::Timeline */}

        {event_details?.schedule?.length > 0 ? (
          <div className='timeline-label'>
            {event_details?.schedule?.map((schedule: any, i: number) => {
              return (
                <div className='timeline-item mb-15' key={i}>
                  {/* begin::Label */}
                  <div className='timeline-label fw-bold text-gray-800 fs-6 '>
                    <Moment date={schedule?.startdatetime} format='hh:mm a' /> - {''}
                    <Moment date={schedule?.enddatettime} format='hh:mm a' />
                    <br />
                    {schedule?.module == 'live_stage' ? (
                      <>
                        <KTSVG path='/media/svg/event/camera-video.svg' className='svg-icon-3 me-2 fw-bold text-gray-800' />{intl.formatMessage({ id: 'SCHEDULE.LIVESTAGE' })}
                      </>
                    ) : (
                      <>
                          <KTSVG path='/media/svg/event/people.svg' className='svg-icon-3 me-2 fw-bold text-gray-800' /> {intl.formatMessage({ id: 'SCHEDULE.SESSION' })}
                      </>
                    )}
                  </div>
                  {/* end::Label */}
                  {/* begin::Badge */}
                  <div className='timeline-badge '>
                    <KTSVG path='/media/svg/event/record-circle-fill.svg' className='svg-icon-5 svg-icon-success' />
                  </div>
                  {/* end::Badge */}
                  {/* begin::Content */}
                  <div className='timeline-content px-10'>
                    <div className='d-flex align-items-start mb-5'>
                      {/* begin::Avatar */}
                      <div className='symbol symbol-50px me-5'>
                        <img
                          src={schedule?.image}
                          className=''
                          alt=''
                        />
                      </div>
                      {/* end::Avatar */}
                      {/* begin::Text */}
                      <div className='flex-grow-1'>
                        <span className='text-dark fw-bold fs-6'>{schedule.title}</span>
                        <span className='text-muted d-block fw-semibold'>
                          {schedule.description ? parse(schedule.description) : ''}
                        </span>
                      </div>
                      {/* end::Text */}
                    </div>
                    {schedule?.guest_details.length > 0 && (
                      <>
                        <span className='text-dark fw-bold fs-6 px-20'>{intl.formatMessage({ id: 'SCHEDULE.GUEST.DETAILS' })}</span>
                        {schedule?.guest_details?.map((guest: any, i: number) => (
                          <div className='d-flex align-items-center mb-5 mt-5 px-20' key={i}>
                            {/* begin::Avatar */}
                            <div className='symbol symbol-50px me-5'>
                              {guest?.image ? <img
                                src={
                                  guest?.image
                                }
                                className=''
                                alt=''
                              /> : <img
                                src={
                                  '/media/avatars/blank.png'
                                }
                                className=''
                                alt=''
                              />}

                            </div>
                            {/* end::Avatar */}
                            {/* begin::Text */}
                            <div className='flex-grow-1'>
                              <span className='text-dark fw-bold fs-6'>
                                {guest?.first_name}{' '}
                                {guest?.last_name}
                                <>
                                  {" "} ({guest?.guest_type})
                                </>
                              </span>
                              <span className='text-muted d-block fw-semibold'>
                                {guest?.headline}
                              </span>
                            </div>
                            {/* end::Text */}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  {/* end::Content */}
                </div>
              )
            })}
          </div>
        ) : (
          <div className='mb-5 pt-5'>{intl.formatMessage({ id: 'SCHEDULE.NOT.FOUND' })}</div>
        )}

        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  </>
  )
}

export { ScedulePage }

import {configureStore} from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import {reducer as toastrReducer} from 'react-redux-toastr'
import EventSlice from './Event/EventSlice'
import LiveStageSlice from './LiveStage/LiveStageSlice'
import SponserSlice from './Sponser/SponserSlice'
import AuthSlice from './Auth/AuthSlice'
import loaderSlice from './Loader/LoaderSlice'
import BoothSlice from './Booth/BoothSlice'
import CompanySlice from './Company/CompanySlice'
import SessionSlice from './Session/SessionSlice'
import AwardSlice from './Award/AwardSlice'

const store = configureStore({
  reducer: {
    toastr: toastrReducer,
    event: EventSlice,
    livestage: LiveStageSlice,
    SponserSlice: SponserSlice,
    authSlice: AuthSlice,
    loader: loaderSlice,
    booth: BoothSlice,
    company: CompanySlice,
    session: SessionSlice,
    award: AwardSlice,
  },
  middleware: [thunk],
})

export default store

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { livestageDetails, livestage_detail } from '../../../../store/LiveStage/LiveStageSlice'
import ReactPlayer from 'react-player'
import { loaderChange } from '../../../../store/Loader/LoaderSlice'
import { convertToLocalTime, getLastValue } from '../../../../utils'
import { useIntl } from 'react-intl'
const parse = require('html-react-parser')

const LiveStageDetail: React.FC = () => {
  const intl = useIntl()
  const { livestage_id } = useParams()
  const dispatch = useDispatch()
  const livestage = useSelector(livestageDetails)
  
  useEffect(() => {
    dispatch(loaderChange(true))
    if (livestage_id) {
      dispatch(livestage_detail({ livestage_id: livestage_id })).then(() =>
        dispatch(loaderChange(false))
      )
    }
  }, [])

  return (
    <>
      <div className={`card row mb-10`}>
     
        <div className='card-body pb-0'>
          <div className='mb-10'>
            {livestage?.stream_provider == "youtube" &&
              <ReactPlayer url={process.env.REACT_APP_YOUTUBE_URL + `${livestage?.stream_url}`} controls={true}
                className="h-550px bgi-no-repeat bgi-position-center bgi-size-cover w-100" />
            }

            {livestage?.stream_provider == "bananry" &&
              <ReactPlayer url={process.env.REACT_APP_BANANRY_URL1 + `${getLastValue(livestage?.stream_url, "/")}` + process.env.REACT_APP_BANANRY_URL2} controls={true}
                className="h-550px bgi-no-repeat bgi-position-center bgi-size-cover w-100" />
            }
          </div>

          <div className='mb-2'>
            <h2 className='text-gray-800 fw-bolder'>{livestage?.title}</h2>
          </div>
          <div className='text-gray-500 fw-semibold mb-10'> {livestage?.startdatetime && livestage?.enddatettime && (
            <>
              {convertToLocalTime(livestage?.startdatetime)} <strong>{intl.formatMessage({ id: "TEXT.TO" })}</strong> {" "}
              {convertToLocalTime(livestage?.enddatettime)}
            </>
          )}</div>
          <div className='fw-mormal text-gray-500 mb-10'>{livestage?.description ? parse(livestage?.description) : ""}</div>

          {livestage?.guests?.length > 0 && (
            <>
              <hr />
              <div className='mt-10 mb-10'>
                <span className='text-dark fw-bold fs-6'>{intl.formatMessage({ id: "SCHEDULE.GUEST.DETAILS" })}</span>
                {livestage.guests.map((guest: any, i: number) => (
                  <div className='d-flex align-items-center mb-5 mt-5' key={i}>
                    {/* begin::Avatar */}
                    <div className='symbol symbol-50px me-5'>
                      {guest?.image ? <img
                        src={
                          guest?.image
                        }
                        className=''
                        alt=''
                      /> : <img
                        src={
                          '/media/avatars/blank.png'
                        }
                        className=''
                        alt=''
                      />}

                    </div>
                    {/* end::Avatar */}
                    {/* begin::Text */}
                    <div className='flex-grow-1'>
                      <span className='text-dark fw-bold fs-6'>
                        {guest?.first_name}{' '}
                        {guest?.last_name}
                        <>
                          {" "} ({guest?.guest_type})
                        </>
                      </span>
                      <span className='text-muted d-block fw-semibold'>
                        {guest?.headline}
                      </span>
                    </div>
                    {/* end::Text */}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

      </div>

    </>
  )
}

export { LiveStageDetail }

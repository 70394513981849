import React, { useState } from 'react'
import Moment from 'react-moment'
import { PollDropDown } from '../content/dropdown/PollDropDown'
import clsx from 'clsx'
import { PageTitle } from '../../layout/core'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap'
import { KTSVG } from '../../helpers'
import { PollState } from '../../../app/modules/Socket/PollProvider'

const Poll = ({ poll, submitPolls, type }: any) => {
  const user_type = localStorage.getItem('user_type')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [shareModal, setShareModal] = useState(false)
  const [optId, setOptId] = useState('')
  const [pollId, setPollId] = useState('')
  const { sharePoll, deletePoll } = PollState()


  return (
    <>
      <tr style={{ overflow: 'hidden' }}>
        <td className='min-w-350px'>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='w-225px'>
              <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-5 me-3'>
                {poll?.quetion ?? ''}
              </a>
              <br />
              <span className='text-muted fw-semibold d-block fs-7'>
                <Moment fromNow>{poll.created_at}</Moment>
              </span>
            </div>
            {user_type === 'owner' && (
              <div className='d-flex'>
                <button className='btn btn-sm btn-primary btn-active-light-primary me-3'
                  onClick={() => { setPollId(poll.id); setShareModal(true) }}>
                  Share
                </button>
                {type === 'share_poll' &&
                  <button className='btn btn-sm btn-icon btn-light btn-active-light-primary '
                    onClick={() => { setPollId(poll.id); setDeleteModal(true) }}>
                    <i className='bi bi-trash fs-2'></i>
                  </button>
                }
              </div>
            )}
          </div>

          {poll.Events_PollOptions.length > 0 &&
            poll.Events_PollOptions.map((opt: any, i: number) => (
              <div
                key={i}
                className={`d-flex flex-column w-100 me-2 mt-2 ${!poll.isPollAdded ? 'bg-gray-100i' : ''
                  }`}
                onClick={() => {
                  if (!poll.isPollAdded) {
                    submitPolls(opt.id)
                  }
                }}
              >
                {user_type === 'owner' &&
                  type === 'share_result' ?
                  <>
                    <div className='d-flex flex-stack p-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>
                        Answer -{' '}
                        <span className='text-primary me-2 fs-7 fw-semibold'>
                          {Number(opt.perOfPoll).toFixed(2)}%
                        </span>
                      </span>
                      <span className='text-end'>{opt.TotalCount} vote</span>
                    </div>
                  </> : type === 'share_poll' ?
                    <>
                      <div className={`d-flex flex-stack border border-gray-400 border-solid rounded cursor-pointer ${poll.login_user_selected_option !== opt.id ? "bg-hover-primary text-hover-white" : "bg-success disabled"} ${poll.login_user_selected_option && 'disabled'}`}>
                        <span className='me-2 fs-7 fw-semibold p-2 px-3 text-gray '>
                          {opt.options}
                        </span>
                      </div>
                    </> : ''
                }
                {user_type === 'user' &&
                  type === 'share_result' ?
                  <>
                    <div className='d-flex flex-stack p-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>
                        Answer -{' '}
                        <span className='text-primary me-2 fs-7 fw-semibold'>
                          {Number(opt.perOfPoll).toFixed(2)}%
                        </span>
                      </span>
                      <span className='text-end'>{opt.TotalCount} vote</span>
                    </div>
                  </> : ''
                }
                {/* {((type === 'share_result')) && (
                )}
                {(poll.is_share && type === "share_poll" || user_type==='owner') &&
                  (
                  )
                } */}

                {((type === 'share_result')) && (
                  <div className='progress h-6px w-100'>
                    <div
                      className='progress-bar bg-primary'
                      role='progressbar'
                      style={{ width: `${opt.perOfPoll}%` }}
                    ></div>
                  </div>
                )}
              </div>
            ))}
          {/* {!poll.isPollAdded &&
            <div className='d-flex justify-content-end'>
              <button
                className='btn btn-primary p-2 mt-2 btn-active-light-primary '
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                onClick={() => submitPolls(optId)}
              >
                Submit Poll
              </button>
            </div>
          } */}
        </td>
        {/* {user_type === 'owner' && (
          <td className='align-baseline'>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary '
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={() => setIsMenuOpen((prev) => !prev)}
            >
              <i className='bi bi-three-dots-vertical fs-2'></i>
            </button>
          </td>
        )} */}
      </tr>
      <PollDropDown isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} poll={poll} type={type} />
      <hr />
      <Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
        {/* <ModalHeader>
          <div className='text-center w-100'>
            <div className='d-flex justify-content-between'>
              <h2 className='m-auto'>Delete Poll</h2>
              <h3 style={{ cursor: 'pointer' }} onClick={() => setDeleteModal(false)}>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </h3>
            </div>
          </div>
        </ModalHeader> */}
        <ModalBody className='p-8'>
          Are you sure you want to delete poll?
        </ModalBody>
        <ModalFooter>
          <div className='text-end'>
            <button className='btn btn-secondary mx-2' onClick={() => { setDeleteModal(false) }}>
              Cancel
            </button>
            <button className='btn btn-primary' onClick={() => { deletePoll(Number(pollId)); setDeleteModal(false) }}>
              Delete
            </button>
          </div>
        </ModalFooter>
      </Modal>

      <Modal show={shareModal} onHide={() => setShareModal(false)}>
        {/* <ModalHeader>
          <div className='text-center w-100'>
            <div className='d-flex justify-content-between'>
              <h2 className='m-auto'>Share Poll</h2>
              <h3 style={{ cursor: 'pointer' }} onClick={() => setShareModal(false)}>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </h3>
            </div>
          </div>
        </ModalHeader> */}
        <ModalBody className='p-8'>
          Are you sure you want to share poll with other people?
        </ModalBody>
        <ModalFooter>
          <div className='text-end'>
            <button className='btn btn-secondary mx-2' onClick={() => { setShareModal(false) }}>
              Cancel
            </button>
            <button className='btn btn-primary' onClick={() => { sharePoll(Number(pollId), type); setShareModal(false) }}>
              Share
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default Poll

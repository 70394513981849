import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {API_SESSION_DETAILS, API_SESSION_LIST} from '../../constants/Api'

const initialState = {
  message: '',
  error: null,
  session: {},
  sessions: [],
}

export const session_detail: any = createAsyncThunk('session_detail', async (data: any) => {
  let res = await axios(API_SESSION_DETAILS + data.session_id)
  return res.data
})

export const session_list: any = createAsyncThunk('session_list', async (data: any) => {
  let res = await axios(API_SESSION_LIST + data.event_id)
  return res.data
})

// Reducer
const SessionSlice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //session_detail
    builder.addCase(session_detail.fulfilled, (state, action: any) => {
      state.session = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })
    builder.addCase(session_detail.rejected, (state, action: any) => {
      state.session = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })

    //session_list
    builder.addCase(session_list.fulfilled, (state, action: any) => {
      state.sessions = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })
    builder.addCase(session_list.rejected, (state, action: any) => {
      state.sessions = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })
  },
})

export default SessionSlice.reducer

export const SessionList = (state: any) => state.session.sessions
export const SessionDetails = (state: any) => state.session.session

import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {API_AUTH, API_SUBSCRIBE_NEWSLATTER, API_COMPLAINT_ADD} from '../../constants/Api'
import queryString from 'query-string'
import {setLanguageWithoutReaload} from '../../_metronic/i18n/Metronici18n'

const initialState = {
  message: '',
  error: null,
  auth_details: {},
}

const parsed = queryString.parse(window.location.search)
let event_id = parsed?.event_id?.toString() || ''

export const auth_details: any = createAsyncThunk('auth_details', async (data: any) => {
  return await axios
    .post(API_AUTH, data)
    .then((response: any) => {
      // set language
      setLanguageWithoutReaload(response?.data?.data?.language || 'en')
      localStorage.setItem('event_id', event_id)
      localStorage.setItem('type', response?.data?.data?.event?.type || '0')
      localStorage.setItem('event_type', response?.data?.data?.event?.event_type)
      localStorage.setItem('bananryCompanyId', response?.data?.data?.event?.company_id)
      localStorage.setItem('user_id', response.data?.data?.id)
      localStorage.setItem('user_name', response.data?.data?.name)
      localStorage.setItem('email', response.data?.data?.email)
      localStorage.setItem('room', response.data?.data?.room_name)
      localStorage.setItem('groupId', response.data?.data?.group_id)
      localStorage.setItem('event_name', response.data?.data?.event_name)
      localStorage.setItem('user_type', response.data?.data?.user_type || 'user')
      localStorage.setItem('event_status', response.data?.data?.status)
      localStorage.setItem('token', response.data?.data?.token)
      localStorage.setItem('host_id', response?.data?.data?.event?.createdby)
      localStorage.setItem('language', response?.data?.data?.language || 'en')
      localStorage.setItem(
        'profile_pic',
        response.data?.data?.profile_pic || '/media/avatars/blank.png'
      )
      return response.data
    })
    .catch((err) => {
      console.log(err)
      return err
    })
})

export const subscribe: any = createAsyncThunk('subscribe', async (data: any) => {
  return await axios
    .post(API_SUBSCRIBE_NEWSLATTER, data)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      console.log(err)
      return err
    })
})

export const add_complaints: any = createAsyncThunk('add_complaints', async (data: any) => {
  return await axios
    .post(API_COMPLAINT_ADD, data)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      console.log(err)
      return err
    })
})

// Reducer
const AuthSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //auth_details
    builder.addCase(auth_details.fulfilled, (state, action: any) => {
      let event_id = parsed?.event_id?.toString() || ''
      let data = action.payload?.data
      state.auth_details = data
      state.message = action.payload?.message
      state.error = null

      // window.location.href = `event/${event_id}/reception`

      // if (data?.user_type == 'owner' || data?.status == 1) {
      //   window.location.href = `event/${event_id}/reception`
      // }
    })

    builder.addCase(auth_details.rejected, (state, action: any) => {
      state.auth_details = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })
  },
})

export default AuthSlice.reducer

export const eventDetails = (state: any) => state.event.event

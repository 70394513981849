
import React from 'react'
import parse from "html-react-parser"
import { useIntl } from 'react-intl'

type Props = {
    event_details: any
}

const EventDetails: React.FC<Props> = ({ event_details }) => {
    const intl = useIntl()
    return (
        <div
            className='shadow-xs card-rounded mx-15 mb-15 px-6 py-1 position-relative z-index-1 bg-body'
        >
            <div className={`card row`}>
                <div className='card-header card-title m-0 pt-10'>
                    <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: "TEXT.EVENT_DETAILS" })}</h3>
                </div>
                <div className='card-body pb-0'>
                    <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: "TEXT.RULES" })}</label>

                        <div className='col-lg-8 fv-row'>
                            <span className='fw-bold fs-6'>{
                                event_details?.event?.rules
                                    ? parse(event_details?.event?.rules)
                                    : ''
                            }</span>
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: "TEXT.DISCLAIMER" })}</label>

                        <div className='col-lg-8 fv-row'>
                            <span className='fw-bold fs-6'>{
                                event_details?.event?.disclaimer
                                    ? parse(event_details?.event?.disclaimer)
                                    : ''
                            }</span>
                        </div>
                    </div>

                    <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: "TEXT.HISTORY_AND_OVERVIEW" })}</label>

                        <div className='col-lg-8 fv-row'>
                            <span className='fw-bold fs-6'>{
                                event_details?.event?.history_overview_company
                                    ? parse(event_details?.event?.history_overview_company)
                                    : ''
                            }</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}
export { EventDetails }

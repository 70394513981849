import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {API_LIST_OF_AWARDS, API_LIST_OF_NOMINEES, API_VOTE_NOW} from '../../constants/Api'

const initialState = {
  message: '',
  error: null,
  awards: [],
  nominees: [],
}

export const list_of_awards: any = createAsyncThunk('list_of_awards', async (data: any) => {
  return await axios
    .post(API_LIST_OF_AWARDS, data)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      console.log(err)
      return err
    })
})

export const list_of_nominees: any = createAsyncThunk('list_of_nominees', async (data: any) => {
  return await axios
    .post(API_LIST_OF_NOMINEES, data)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      console.log(err)
      return err
    })
})

export const vote_now: any = createAsyncThunk('vote_now', async (data: any) => {
  return await axios
    .post(API_VOTE_NOW, data)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      console.log(err)
      return err
    })
})

// Reducer
const AwardSlice = createSlice({
  name: 'award',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //list_of_awards
    builder.addCase(list_of_awards.fulfilled, (state, action: any) => {
      state.awards = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })

    builder.addCase(list_of_awards.rejected, (state, action: any) => {
      state.awards = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })

    //list_of_nominees
    builder.addCase(list_of_nominees.fulfilled, (state, action: any) => {
      state.nominees = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })
    builder.addCase(list_of_nominees.rejected, (state, action: any) => {
      state.nominees = action.payload?.data
      state.message = action.payload?.message
      state.error = null
    })
  },
}) 

export default AwardSlice.reducer

export const awards = (state: any) => state.award.awards
export const nominees = (state: any) => state.award.nominees

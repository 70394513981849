/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
  title?: string
}

const TablesWidget5: React.FC<Props> = ({className, title}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-1 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-active-light-primary me-n5'
            id='kt_announcements_close'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <i className='bi bi-megaphone-fill fs-2'></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <p className='text-dark fw-bold mb-1 fs-6'>Brad Simmons</p>
                      <span className='text-muted fw-semibold d-block'>Movie Creator</span>
                    </td>
                    <td className='text-end'>16m</td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <i className='bi bi-megaphone-fill fs-2'></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <p className='text-dark fw-bold mb-1 fs-6'>Popular Authors</p>
                      <span className='text-muted fw-semibold d-block'>Most Successful</span>
                    </td>
                    <td className='text-end'>16m</td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <i className='bi bi-megaphone-fill fs-2'></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <p className='text-dark fw-bold mb-1 fs-6'>New Users</p>
                      <span className='text-muted fw-semibold d-block'>Awesome Users</span>
                    </td>
                    <td className='text-end'>16m</td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <i className='bi bi-megaphone-fill fs-2'></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <p className='text-dark fw-bold mb-1 fs-6'>Active Customers</p>
                      <span className='text-muted fw-semibold d-block'>Movie Creator</span>
                    </td>
                    <td className='text-end'>16m</td>
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>

          {/* end::Tap pane */}
        </div>
      </div>

      {/* end::Body */}

      <div className='card-footer'>
        <div className='text-end'>
          <button
            className='btn btn-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_announcement'
          >
            New
          </button>
        </div>
      </div>
    </div>
  )
}

export {TablesWidget5}
